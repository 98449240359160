import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { ResourceModel } from "../../model";
import {ResourceForm} from "./form/ResourceForm";

export class ResourceCreate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      resource: {} as ResourceModel
    }
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'Add new resource'}>
          <ResourceForm {...this.state}/>
        </CreateLayout>
    )
  }
}