import * as React from "react";
import { AgeGroupModel, AnsFeedbackModel, SportModel } from "../../model";
import { IState } from "../../common/State";
import { actionButton, CreateLink, ExportButton } from "../../common";
import { RouteComponentProps } from "react-router";
import { ListBreadcrumb } from "./Data";
import { ShowRecords, Icons } from "../../common";
import { ListForm, ListHeader, ListingLayout } from "../../layout/Listing";
import _, { get } from "lodash";
import { list_key } from "../../helpers/OrderHelper";
import Filters from "./Filters";
import { humanifyDate } from "../../helpers/UserHelper";
import { EditFeedbackModel } from "./EditFeedbackModel";
import { QuestionnaireService, SportService } from "../../services";

interface IFState extends IState {
  show: boolean;
  feedback: AnsFeedbackModel;
  age_groups: Array<AgeGroupModel>;
  sports: Array<SportModel>;
}
export class AnsFeedback extends React.Component<RouteComponentProps, IFState> {
  records: React.RefObject<ShowRecords> = React.createRef();
  show: boolean = false;

  componentDidMount() {
    const ___that = this;
    async function init() {
      const ageGroupList = await QuestionnaireService.getAgeGroups();
      const sportsList = await SportService.getList();
      ___that.setState({
        show: false,
        age_groups: [{ label: "Select Age group", value: "" }, ...ageGroupList],
        sports: [{ label: "Select Sport", value: "" }, ...sportsList],
      });
    }
    init();
  }

  showContent = (data: AnsFeedbackModel, index: number) => {
    let cols = [];
    cols.push(
      ...[
        <td key={list_key(index)}>{this.records.current.checkbox(data.id)}</td>,
        this.records.current.createdTd(
          "sport_id",
          get(data, "sport.name", "-")
        ),
        this.records.current.createdTd(
          "age_group_id",
          get(data, "age_group.title", "-")
        ),
        this.records.current.createdTd(
          "concept_id",
          get(data, "concept.name", "-")
        ),
        this.records.current.createdTd(
          "marks",
          `${data.min_marks}-${data.max_marks}`
        ),
        this.records.current.createdTd("feedback", data.feedback),
        this.records.current.createdTd(
          "created_at",
          humanifyDate(data.created_at)
        ),
        this.records.current.createdTd("action", () =>
          this.rowActionButtons(data)
        ),
      ]
    );
    cols = _.compact(cols);
    return <tr key={list_key(data.id)}>{cols}</tr>;
  };

  filterRecords = (data?: any) => {
    if (this.records && this.records.current !== null) {
      if (data !== undefined) {
        this.records.current.setFormFields(data);
      } else {
        this.records.current.setFormFields();
      }
    }
  };

  rowActionButtons = (data: AnsFeedbackModel) => {
    return (
      <span style={{ overflow: "visible", position: "relative", width: "110px" }}>
        <button
          className={"btn btn-sm"}
          onClick={(e) => {
            console.log(data);
            this.setState({ show: true, feedback: data })
          }}
          title={"Update feedback"} >
          <i className={Icons.edit} />
        </button>
      </span>
    );
  };

  render() {
    return (
      <ListingLayout breadcrumb={ListBreadcrumb}>
        <ListHeader title={"Feedbacks"} icon={Icons.football}>
          <ExportButton records={this.records} fileName={"feedback"} />
          &nbsp;
          <CreateLink
            to={`/quiz/ans-feedback/create`}
            scope={"ans-feedback.store"}
          />
        </ListHeader>

        <ListForm
          form={<Filters getResults={this.filterRecords}  age_groups={ _.get(this.state, "age_groups", []) } sports={ _.get(this.state, "sports", [])}  />}
          table={
            <ShowRecords
              url={"/quiz/ans-feedback"}
              showRecords={this.showContent}
              ref={this.records}
              isNeedAction={true}
              actionButtons={actionButton({
                buttons: ["Delete"],
                records: this.records,
                url: "quiz/ans-feedback/bulk-action",
                module: "ans-feedback",
              })}
              rowActions={this.rowActionButtons}
            />
          }
        />
        <EditFeedbackModel
          show={get(this.state, "show", false)}
          onHide={(refresh) => {
            this.setState({ show: false, feedback: {} as AnsFeedbackModel });
            if (refresh === true) {
              this.records.current.fetchData();
            }
          }}
          age_groups={ _.get(this.state, "age_groups", []) } 
          sports={ _.get(this.state, "sports", [])} 
          feedback={get(this.state, "feedback", {} as AnsFeedbackModel)}
        />
      </ListingLayout>
    );
  }
}
