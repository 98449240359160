import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {PageForm} from "./form/PageForm"
import {PageModel} from "../../model";
import API from "../../common/AxoisClient";

export class PageUpdate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      page: {} as PageModel
    }
  }

  init = async () => {
    const res = await API.get(`settings/pages/${this.state.id}`);
    this.setState({
      page: res.data
    })
  }

  componentDidMount() {
    this.init();
  }


  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'Edit'}>
          <PageForm {...this.state}/>
        </CreateLayout>
    )
  }
}