import React from 'react';
import {Icons} from "./Icon";
import {Link} from "react-router-dom";

interface GridsSchema {
  title: string;
  count: string|number;
  icon: string;
  link: string;
  class?: string;
}

export function Grids (props:{data: Array<GridsSchema>}){
  const {data} = props;
  return(
    <div className="datalisting" style={{height: "67vh"}}>
      <div className="row">
        {
          data.length > 0 && data.map((item:GridsSchema, index:number) => {
            return (
             
              <div className="col-lg-3" key={index}>
                
                <div className={`kt-portlet kt-iconbox ${item.class}  kt-iconbox--animate-slow`}>
                 
                    <div className="kt-iconbox__body">
                      <div className="kt-iconbox__icon large_icon">
                        <i className={Icons[item.icon]} />
                      </div>
                      <div className="kt-iconbox__desc">
                        <h3 className="kt-iconbox__title">
                          <Link className="kt-link" to={item.link}>{item.title}</Link>
                        </h3>
                      { item.count !== undefined &&
                        <div className="kt-iconbox__content">
                         <span className={'badge badge-success'}> {item.count}</span>
                        </div>
                }
                      </div>
                    </div>
                  
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
