import * as React from 'react';
import {Sidebar} from "../partial/Sidebar";
import Header from "../partial/Header";
import {Footer} from "../partial/Footer";
import {Link} from "react-router-dom";

declare var window: any;


export const Web = (props: any) => {
  return (
    <div>
      <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
        <div className="kt-header-mobile__logo">
          <Link to={'#'}>
            <img alt="Logo" src={`${window.PUBLIC_URL}/favicon-mobile.ico`} />
          </Link>

        </div>
        <div className="kt-header-mobile__toolbar">
          <span className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><i className="flaticon2-soft-icons" style={{color:'white', fontSize:'18px'}}/></span>
          <span className="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more" style={{color:'white', fontSize:'18px', padding:'10px'}}/></span>
        </div>
      </div>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

          {/*begin:: Aside*/}
          <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"/></button>
          <Sidebar/>


          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">

            {/*begin:: Header*/}
            <Header/>
            {/*end:: Header*/}

            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor min-vh-height" id="kt_content">

              {/*begin:: Content*/}
              {props.children}

              {/*end:: Content*/}
            </div>

            {/*begin:: Footer*/}
            <Footer/>
            {/*end:: Footer*/}
          </div>
        </div>
      </div>
    </div>
  );
}