import * as React from 'react';
import _ from "lodash";
import {Select} from "./Select";
import {DatePickerWrapper} from "./DatePicker";
import AsyncSelect from 'react-select/async';
import {SummerNote} from "./Summernotes";
import CreatableSelect from 'react-select/creatable';


export const FormElementGroup = (props: any) => {
  return (
      <div className={"col-md-6 col-lg-6"}>
          <div className="form-group">
              { props.children }
          </div>
      </div>
  )
}

export function renderOptions(options) {
  return options.map((item, index) => {
    return <option value={item.id} key={index}>{ item.name }</option>
  })
}


const InputFields = (field) => {
  const [file, setFile] = React.useState("Choose file");

    const {type} = field;
    switch (type) {
        case "select":
            const {options, ...rest}  = field;
            return <Select className="form-control" {...rest}>
                { !_.isEmpty(rest.placeholder) && <option value={""}>{ rest.placeholder}</option> }
                { renderOptions (options) }
            </Select>

      case "tags":
        return <CreatableSelect cacheOptions {...field}/>

      case "select2":
        return <AsyncSelect cacheOptions {...field}/>

        case "date":
          return <DatePickerWrapper className="form-control" {...field}/>

      case "file":
          const {onChange,  ...fileField} = field;
          const onFileChange = event => {
            setFile(event.target.files[0].name);
            onChange(event);
          }

          return <React.Fragment>
            <div/>
            <div className="custom-file" style={{zIndex: 0}}>
              <input className="custom-file-input" onChange={onFileChange} {...fileField} id={"customFile"} />
              <label className="custom-file-label" htmlFor="customFile">{file}</label>
            </div>
          </React.Fragment>

        case "editor":
            //return <Editor apiKey={CONST.editorKey} id='message' init={CONST.initialEditorOptions} {...field} />
              return <SummerNote {...field}/>

        case "textarea":
          //return <Editor apiKey={CONST.editorKey} id='message' init={CONST.initialEditorOptions} {...field} />
            return <textarea className="form-control" {...field}></textarea>
        default:
            return <input className="form-control" {...field}/>
    }
}


export const RenderForm = ({items}) => {
    return items.map((field, index) => {
      const {label, required, parent_class, show, extra,  ...rest} = field;
      if(show !== undefined && show === false){
        return null;
      }
      return (
        <div className={parent_class ? parent_class : "col-md-6 col-lg-6"} key={index}>
          <div className="form-group">
            <label>{label}
                { required && <span className={'required'}>*</span>}
            </label>
            <InputFields {...rest}/>
            {extra && extra}
            {
              (field.type === "file" &&  !_.isEmpty(field.file_path)) && <div style={{marginTop:10}}>
                <img src={field.file_path} alt={field.file_path} className={"img-responsive"} width={100} height={100}/>
              </div>
            }
          </div>
        </div>
      )
    })
}

export function ansRange() {
  const range = [];
  for(var i =0; i<=10; i++) {
    range.push({
      id: `${i}`,
      name: i
    })
  }
  return range;
}