import * as React from 'react';
import {Select} from "./Select";
import {DatePickerWrapper} from "./DatePicker";

export interface SelectOptions {
  label: string;
  value: string|number
}

export interface SelectProps extends  React.InputHTMLAttributes<HTMLElement> {
  options: Array<SelectOptions>;
}


export const SearchInput = (props: React.InputHTMLAttributes<HTMLElement>) => {
  const newProps = {...props, className: `form-control ${props.className}`};
  return (
    <div className="col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile">
      <div className="kt-input-icon kt-input-icon--left">
        <input type="text" {...newProps} />
        <span className="kt-input-icon__icon kt-input-icon__icon--left">
          <span><i className="la la-search"/></span>
        </span>
      </div>
    </div>
  )
};

export const DateInput = (props: React.InputHTMLAttributes<HTMLElement>) => {
  const newProps = {...props, className: `form-control ${props.className}`, options: { startDate: new Date('1970-01-01')}};
  return (
    <div className="col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile">
      <div className="kt-input-icon kt-input-icon--left">
        <DatePickerWrapper {...newProps}/>
        <span className="kt-input-icon__icon kt-input-icon__icon--left">
          <span><i className="la la-search"/></span>
        </span>
      </div>
    </div>
  )
};


export const SearchSelect = (props: SelectProps) => {
  const newProps = {...props, className: `form-control custom-select ${props.className}`};
  return (
    <div className="col-md-3 mb-3 kt-margin-b-20-tablet-and-mobile">
      <div className="kt-form__group kt-form__group--inline">
        <div className="kt-form__label">
          <label/>
        </div>
        <div className="kt-form__control">
          <Select {...newProps}>
            {props.options && props.options.map((item : SelectOptions, index: number) => {
              return <option value={item.value} key={index}>{item.label}</option>
            })}
          </Select>
        </div>
      </div>
    </div>
  )
};

