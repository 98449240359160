import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {SportModel} from "../../../model";
import {IState} from "../Data";
import { SportService} from "../../../services";

export const SportForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as SportModel);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]: value})
  }

  React.useEffect(() => {
    setForm(props.sport)
  },[props.sport])


    const FormInputs = [
      {
        "name" : "name",
        "value" : _.get(form, "name", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter name",
        "label": "Name",
      },
    ];

    function isDisable() {
      const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
      return fields;
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id ){
      SportService.update(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/sports`)
      }).catch(() => {});
    } else {
      SportService.store(form).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/sports`)
      }).catch(() => {});
    }
  }

  return(
      <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
          </div>
        </div>
        <CommonSubmitButton disabled={isDisable()}/>
      </form>
    )
}
