import Lang from "../../common/lang/Lang";

export const urls = {
  users: "/users/",
  list: "/users/users",
}

export const CustomerBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: "Front Users",
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: "Front Users",
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: "Front Users",
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  }
];
export const CreateOrderBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.order,
    url: '#'
  },
  {
    label: Lang.create_order,
    url: '#'
  }
];

export const ChangePwdBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  },
  {
    label: Lang.change_password,
    url: '#'
  }
];

export const ActivityLogBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.customers,
    url: urls.list
  },
  {
    label: Lang.info,
    url: '#'
  },
  {
    label: Lang.activity_logs,
    url: '#'
  }
];

export interface Form {
  role_id: number;
  age_group_id: number;
  first_name: string;
  last_name: string;
  email: string;
  password:string;
  cn_password:string;
  mobile_no:string|number;
  gender:string;
  dob:string;
  sendmail: string|boolean
}

interface Roles {
  id: number;
  name: string
}

export interface IState {
  form: Form,
  id?: number;
  roles: Array<Roles>
}
export interface ImportIState {
  file: string
}

export enum ACTIONS {
  COMPANY_DEFAULT = 'COMPANY_DEFAULT',
  NOT_SPECIAL = 'NOT_SPECIAL',
  SPECIAL = 'SPECIAL',
}

export const NotificationBreadcrumb = [
  {
    label: Lang.users,
    url: urls.users
  },
  {
    label: Lang.notification,
    url: '#'
  }
];