import * as React from 'react';
import {Auth} from "../Auth/Auth";
import {Link} from "react-router-dom";
import Lang from "../common/lang/Lang";
import {SwitchLoader, LoaderType, Icons, GeneratePermitLink} from "../common";
import API, {base_url} from "../common/AxoisClient";
import {withCookies} from "react-cookie";
import {UserProfileService} from "../services";
const moment = require("moment");
 
declare var window: any;

interface IState{
  Notificationlist?:Array<any>;
  username:string;
  limit :number;
  notify_total : number;
}

class Header extends React.Component<any, IState> {
  state: IState;

  constructor(props: any){
    super(props);

   

    this.state = {
      Notificationlist : [],
      username : Auth.userInfo.name,
      limit : 10,
      notify_total : 0,
    }
  }
  
  componentDidMount() {
    let notifi = this; 
    this.getnotification()

    UserProfileService.getPopupState().subscribe((res:any) => {
      this.setState({
       username : Auth.userInfo.name,
      })
    })

    /*NotificationListNewService.getPopupState().subscribe((res:any) => {
        this.getnotification()
    })*/

    $('body').on('click','#notify',function(){
       if($('.kt-header__topbar-item').hasClass('show')) {
          $(".kt-header__topbar-item").removeClass('show');
          $(".notific").removeClass('show');
		  $(".newclassAdd").removeClass('ps');
        }
        else{
           $(".kt-header__topbar-item").addClass('show');
           $(".notific").addClass('show');
		    $(".newclassAdd").addClass('ps');
         }
     });

     $('body').on('click','.notificationView',function(){
      notifi.notificationCountUpdate();
    });
  }
 
  getnotification =()=>{
   API.get(`notification-list/${this.state.limit}`).then(res => {
      this.setState({
        Notificationlist: res.data.notification,
        notify_total :  res.data.total
      })
    })
    SwitchLoader(LoaderType.NONE);
  }

  notification_icon =(icon_type)=>{
    if(icon_type === 'ORDER_UPDATE'){
      return <i className="fas fa-clipboard-check"/>
    } else if(icon_type === "order_edit_request_sent_by_customer"){
      return <i className="fas fa-exchange-alt"/>
    } else if(icon_type === "order_cancel_request_sent_by_customer"){
      return <i className="fas fa-window-close"/>
    } else if(icon_type === "ORDER_Asssing"){
      return <i className="fas fa-file-signature"/>
    } else if(icon_type === "ORDER_in_progress"){
      return <i className="fas fa-file-export"/>
    } else if(icon_type === "ORDER_complete"){
      return<i className="fas fa-clipboard-check"/>
    } else if(icon_type === "ORDER_claim"){
      return<i className="fas fa-chalkboard-teacher"/>
    } else if(icon_type === "ORDER_claim_approved"){
      return<i className="fas fa-check-square"/>
    } else if(icon_type === "ORDER_claim_decline"){
      return<i className="fas fa-minus-circle"/>
    } else if(icon_type === "update_profile"){
      return<i className="fas fa-id-badge"/>
    } else if(icon_type === "CUSTOMER_PROFILE_INFORMATION_UPDATE"){
      return<i className="fas fa-id-badge"/>
    } else if(icon_type === "change_password"){
      return <i className="fas fa-lock"/>
    } else if(icon_type === "assign_translator"){
      return<i className="fas fa-folder-plus"/>
    } else if(icon_type === "translator_send_invitation"){
      return<i className="fas fa-envelope-open-text"/>
    } else if(icon_type === "contract_send"){
      return <i className="fas fa-file-import"/>
    } else if(icon_type === "order_complete_on_feed_back"){
      return  <i className="fas fa-envelope-open-text"/>
    } else if(icon_type === "default"){
      return <i className="fas fa-bell"/>
    }else {
      return <i className="fas fa-bell"/>
    }
  }

  notificationupdate =(id)=>{
    API.get(`notification-update/${id}`).then(res => {
      this.getnotification()
     });
    SwitchLoader(LoaderType.NONE);
  }

   notificationCountUpdate =()=>{
     
     API.get(`notification-viewupdate`).then(res => {
      this.getnotification()
     });
    SwitchLoader(LoaderType.NONE);
  }
 
  onLoadMore = (event : React.ChangeEvent<any>)=>{
  this.setState({
    limit : this.state.limit + 5,
   }, this.getnotification)
   
}

  Logout = () => {
    const {cookies}: any = this.props;
    let token = cookies.get('remember_token');
    cookies.set('remember', false, { path: '/', HttpOnly:true, sameSite:true, expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
    cookies.set('remember_token', '', { path: '/', HttpOnly:true, sameSite:true, expires : new Date(moment(new Date()).subtract(3600, 'minutes')) });
    Auth.systemlogout(token).then(res => {
      Auth.signout();
      window.location.href = '/';
    });
  }
  render() {
    
    return (
      <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed ">


        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"/></button>
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
           
          </div>
        </div>


        <div className="kt-header__topbar">

          <div className="kt-header__topbar-item dropdown notificationView">
            <div className="kt-header__topbar-wrapper" data-toggle="dropdown"   data-offset="30px,0px"  aria-expanded="true">
									<span className="kt-header__topbar-icon kt-pulse kt-pulse--brand" >
										<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
											<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<rect id="bound" x="0" y="0" width="24" height="24" />
												<path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" id="Combined-Shape" fill="#000000" opacity="0.3" />
												<path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" id="Combined-Shape" fill="#000000" />
											</g>
										</svg> 
                    { this.state.notify_total > 0 &&
                    <span className="kt-pulse__ring"/>
                   }
									</span>

            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg notific ">
              <form>
                <div className="dropdown-bgavatar kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b">
               <h3 className="kt-head__title"> User Notifications  <span className="btn btn-success btn-sm btn-bold btn-font-md">{this.state.notify_total} new</span>
                  </h3>
                  <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active show" data-toggle="tab" href="#topbar_notifications_notifications" role="tab" aria-selected="true"/>
                    </li>
                   </ul>
                </div>

                <div className="tab-content">
                  <div className="tab-pane active show" id="topbar_notifications_notifications" role="tabpanel">
                    <div className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll ps ps--active-y ps--scrolling-y custmScroll"  style={{height:"300px",overflow:"hidden"}}data-scroll="true" data-height="300" data-mobile-height="200" > 
                    {this.state.Notificationlist.map((item, key) => {
                       let message:any = $.parseJSON(item.data)
                        
                      return (
                       
                        <React.Fragment key={key}>
                          
                          <Link  to={`/${message.url}`} onClick={() => this.notificationupdate(item.id)} id={"notify"} className= {(item.read_at !== null) ? "kt-notification__item" : "kt-notification__item  unread_at"} >
                            <div className="kt-notification__item-icon">
                      {this.notification_icon(message.type)}
                             </div>
                            <div className="kt-notification__item-details">
                               <div className="kt-notification__item-time">
                              {message.message}
                              </div>
                            </div>
                          </Link>
                        </React.Fragment>
                      )
                    })
                    
                  }
                 
                  {this.state.Notificationlist.length === 0 &&
                  
                   <div className="kt-notification__item-details">
                   <div className="kt-notification__item-title notFound">
                     {Lang.not_found}
                   </div>
                  
                 </div>
                  }
                  {this.state.Notificationlist.length > 0 && this.state.Notificationlist.length === this.state.limit && 
                <div className="text-center mt-3">
                  <button type={'button'} className="btn btn-success btn-sm btn-bold btn-font-md" onClick={this.onLoadMore} >
                  {Lang.Load_more}
                   </button>
                   </div>
                 }
                   </div>
                  </div>
                  </div>
              </form>
            </div>
          </div>

         <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
              <div className="kt-header__topbar-user">
                <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
                <span className="kt-header__topbar-username kt-hidden-mobile">{ this.state.username }</span>
                <img className="kt-hidden" alt="Pic" src="{{ get_user_image() }}" />
                <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{this.state.username.charAt(0).toUpperCase()}</span>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">


              <div className="dropdown-bgavatar kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
                <div className="kt-user-card__avatar">
                  <img className="kt-hidden" alt="Pic" src="{{ get_user_image() }}" />

                  <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{this.state.username.charAt(0).toUpperCase()}</span>
                </div>
                <div className="kt-user-card__name">
                {this.state.username}
                </div>
                <div className="kt-user-card__badge">
                  <span className="btn btn-success btn-sm btn-bold btn-font-md">{this.state.notify_total} messages</span>
                </div>
              </div>


              <div className="kt-notification">
                  <Link to={`/profile/edit/`} className="kt-notification__item" >
                      <div className="kt-notification__item-icon">
                        <i className="flaticon2-calendar-3 kt-font-success"/>
                      </div>
                      <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title kt-font-bold">
                             {Lang.my_profile}
                        </div>
                        <div className="kt-notification__item-time">
                          Account settings and more
                        </div>
                      </div>
                  </Link>
                {/* <a href="#" className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className="flaticon2-mail kt-font-warning"></i>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title kt-font-bold">
                      My Messages
                    </div>
                    <div className="kt-notification__item-time">
                      Inbox and tasks
                    </div>
                  </div>
                </a> */}

                <GeneratePermitLink scope={'activity-log.index'} to={'/logs/activity-logs/'} className="kt-notification__item">
                  <div className="kt-notification__item-icon">
                    <i className={Icons.activity_log}/>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title kt-font-bold">
                      {Lang.system_activity}
                    </div>
                    <div className="kt-notification__item-time">
                    {Lang.logs_activity}
                      
                    </div>
                  </div>
                </GeneratePermitLink>

                
                <div className="kt-notification__custom kt-space-between">
              
                  <button onClick={this.Logout} className="btn btn-label btn-label-brand btn-sm btn-bold">
                    <i className="fa fa-sign-out" aria-hidden="true"/>
                    {'Logout'}
                  </button>
                 
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default withCookies(Header);