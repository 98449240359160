import React from "react";
import { renderOptions, Select, ansRange, Icons } from "../../../common";
import { IFeedbackInput } from "../../../model";
const AnsRange = ansRange();

type IProps = {
  form: IFeedbackInput;
  onChange: (e: any) => void;
  remove?: React.ReactNode;
}

export const MarkInput = ({ form, onChange, remove }: IProps) => {
  const colClass = remove !== undefined ? 'col-md-5 col-lg-5' : "col-md-6 col-lg-6";
  return (
    <div
      className={"col-md-12 col-lg-12"}
      style={{ borderBottom: "1px solid #d2d3d4", marginTop: 5 }}
    >
      <div className={"row"}>
        <div className={"col-md-12 col-lg-12"}>
          <div className="form-group">
            <label>Feedback</label>
            <textarea
              required={true}
              value={form.feedback || ""}
              onChange={onChange}
              className={"form-control"}
              name={"feedback"}
              placeholder={`Enter feedback`}
            />
          </div>
        </div>

        <div className={"col-md-12 col-lg-12"}>
          <div className={"row"}>
            <div className={colClass}>
              <div className="form-group">
                <label>Statement Range From</label>
                <Select
                  required={true}
                  onChange={onChange}
                  className={"form-control"}
                  name={"min_marks"}
                  value={form.min_marks || ""}
                >
                  <option value={""}>Select Range</option>
                  {renderOptions(AnsRange)}
                </Select>
              </div>
            </div>
            <div className={colClass}>
              <div className="form-group">
                <label>Statement Range To</label>
                <Select
                  required={true}
                  onChange={onChange}
                  className={"form-control"}
                  name={"max_marks"}
                  value={form.max_marks || ""}
                >
                  <option value={""}>Select Range</option>
                  {renderOptions(AnsRange)}
                </Select>
              </div>
            </div>
            {remove !== undefined && (
              <div className={"col-md-1 col-lg-1"}>{remove}</div>
            )}
          </div>
          <div className={"row feedback-warning"}>
            <i className={`kt-menu__link-icon ${Icons.view}`} />
            &nbsp; Please make sure the range does not conflicts with other
            feedbacks statement range
          </div>
        </div>
      </div>
    </div>
  );
};
