import API from "../common/AxoisClient";
import { QuestionnaireTypeModel } from "../model";

export class QuesTypeService {

  static async get(id: number){
    const res = await API.get(`quiz/ques-types/${id}`);
    return res.data
  }


  static async store(data: QuestionnaireTypeModel) {
    const res = await API.post(`quiz/ques-types`, data);
    return res.data
  }

  static async update(data: QuestionnaireTypeModel, id: number){
    const res = await API.post(`quiz/ques-types/${id}`, {...data, "_method": "put"});
    return res.data
  }

  static async getList(){
    const res = await API.get(`quiz/ques-types/get-ques-types-list`);
    return res.data
  }
}