import * as React from 'react'
import {Link, LinkProps} from "react-router-dom";
import Localization, {default as Lang} from "../lang/Lang";
import {Icons} from "../Icon";
import {is_permit} from "../Common";


export interface LinkSchema extends Link{
  scope: string;
  to:string;
}

export interface CreateLinkSchema extends LinkProps {
  scope: string|Array<string>;
  to:string;
}
export interface GeneratePermitLinkSchema extends CreateLinkSchema {
  title?: any;
  children?: React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;
}


export const CreateLink = (params: CreateLinkSchema) => (
  <GeneratePermitLink className={'btn btn-brand btn-elevate btn-icon-sm'}  {...params}><i className={Icons.add} /> {Lang.add_new}</GeneratePermitLink>
)

export const EditLink = (params: CreateLinkSchema) => (
  <span style={{overflow: "visible", position: "relative", width: '110px'}}>
    <GeneratePermitLink className={'btn btn-sm btn-clean btn-icon btn-icon-md'} {...params}><i className={Icons.edit} /></GeneratePermitLink>
  </span>
)
export const ActionLink = (params: CreateLinkSchema) => (
  <span style={{overflow: "visible", position: "relative", width: '110px'}}>
    <GeneratePermitLink className={'btn btn-sm btn-clean btn-icon btn-icon-md'} {...params}>{params.children}</GeneratePermitLink>
  </span>
)

export const GeneratePermitLink = (params: GeneratePermitLinkSchema)=> {
  if(is_permit(params.scope)){
    return (
       <Link {...params}>{params.children}</Link>
    )
  }
  return null;
}