import * as React from 'react';
import {ActionLink, LoaderType, showNotification, SubmitButton} from "../../common";
import API, { SwitchLoader} from "../../common/AxoisClient";

interface ForgotSchema {
  email: string;
  otp: string;
  password: string;
}

export const ForgotPasswordForm = ({showLoginForm}) => {
  
  const [form, setForm] = React.useState({} as ForgotSchema);
  const [openOtp, setOpenOtp] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.post(`forgot-password`, form).then((res) => {
      setOpenOtp(true);
    }).catch(e => console.log(e))
  }

  const handleChange = (event) => {
    const {name, value} = event.target;
    setForm({...form, [name]: value })
  }


  const changePassword = (e) => {
    e.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.post(`reset-password`, form).then((res) => {
      showNotification('success', "Your password has been changed. Please login now.");
      showLoginForm();
    }).catch(e => console.log(e))
  }

  if (openOtp) {
    return (
      <div className="kt-login__signin">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Forgot password</h3>
        </div>
        <form className="kt-form" method="POST" action="" id="login_form" onSubmit={changePassword}>
          <div className="input-group">
            <input id="number" type="number" placeholder="Enter OTP" value={form.otp}  onChange={handleChange} className="form-control" name="otp" required />
          </div>
          <div className="input-group">
            <input id="password" type="password" placeholder="Enter password" value={form.password}  onChange={handleChange} className="form-control" name="password" required />
          </div>
          <div className="row kt-login__extra">
            <div className="col" style={{textAlign: 'right'}} onClick={showLoginForm}>
              Back to login
            </div>
  
          </div>
          <div className="kt-login__actions">
            <SubmitButton className="btn btn-brand btn-elevate kt-login__btn-primary" label={'Submit'}/>
          </div>
        </form>
      </div>
    )
  } else {
    return (
      <div className="kt-login__signin">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Forgot password</h3>
        </div>
        <form className="kt-form" method="POST" action="" id="login_form" onSubmit={handleSubmit}>
          <div className="input-group">
            <input id="email" type="email" placeholder="Email" value={form.email}  onChange={handleChange} className="form-control" name="email" required />
          </div>
          <div className="row kt-login__extra">
            <div className="col" style={{textAlign: 'right'}} onClick={showLoginForm}>
              Back to login
            </div>
  
          </div>
          <div className="kt-login__actions">
            <SubmitButton className="btn btn-brand btn-elevate kt-login__btn-primary" label={'Submit'}/>
          </div>
        </form>
      </div>
    )
  }
}
