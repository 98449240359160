import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from './useDebounceEffect';


function centerAspectCrop( type, mediaWidth, mediaHeight, aspect ) {
  return centerCrop(
    makeAspectCrop(
      type == 'logo' ? {
        unit: 'px',
        width: 200,
      }: {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export function CropImage({file, type, closeModal, show, ...rest}) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1)
  const [aspect, setAspect] = useState(4 /2)

  //const dispatch = useDispatch();

  useEffect(() => {
    
    if(file) {
      if(rest.ratio !== undefined) {
        setAspect(rest.ratio)
      }
      onSelectFile(file)
    }
  }, [file]);

  function onSelectFile(e) {
    setCrop(undefined)
    const reader = new FileReader()
    reader.addEventListener('load', () => {
    setImgSrc(reader.result?.toString() || '')
    }
    
    )
    reader.readAsDataURL(e)
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(type, width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          imageCallbk
        )
      }
    },
    100,
    [completedCrop, scale],
  )

  let cropnImg;
  function imageCallbk(imgB64) {
    cropnImg= imgB64;
  }

  function setImage(e) {
    e.preventDefault();
    //dispatch(handleFileChange({name: type, value: cropnImg}));
    closeModal(cropnImg);
  }

  const handleScroll = (e) => {
    if ( e.deltaY > 0 ) {
      const newScale = scale - 0.1;
      if(newScale >= 0.1) {
        setScale(newScale)
      }
    } else {
      const newScale = scale + 0.1;
      if(newScale <= 1) {
        setScale(newScale)
      }
    }
  }
  
  if(!show) {
    return null;
  }
  return (
    <Modal
      onHide={closeModal}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Crop Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='width-100'>
            <div className="Crop-Controls"  style={{opacity: 0, position: 'fixed'}}>
                <div>
                <input
                    id="scale-input"
                    type="number"
                    step="0.1"
                    value={scale}
                    disabled={!imgSrc}
                    onChange={(e) => setScale(Number(e.target.value))}
                />
                </div>
            </div>
            <div  onWheel={handleScroll}>
                {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => {
                    setCompletedCrop(c)
                    }}
                    aspect={aspect}
                    locked={true}
                >
                    <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{ transform: `scale(${scale})` }}
                    onLoad={onImageLoad}
                   
                    />
                </ReactCrop>
                )}
            </div>
            
            <div style={{
                display: 'flex',
                justifyContent: "space-between"
            }}>
                <div>
                {!!completedCrop && (
                    <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: '80%',
                        height: 'auto',
                    }}
                    />
                )}
                </div>
                <div style={{
                display: 'flex',
                alignSelf: 'center'
                }}>
                <button onClick={setImage} className={'btn btn-primary'}>Save</button>
                </div>
                
            </div>
            
            </div>
        </Modal.Body>
    </Modal>
  )
}

const TO_RADIANS = Math.PI / 180

function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  callback,
  rotate = 0,
  
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )
  canvas.toBlob((blob) => {
    callback(canvas.toDataURL());
   // console.log(canvas.toDataURL(), blob);
  })

  ctx.restore()
}