import * as React from 'react';
import API, {base_url} from "../common/AxoisClient";
import {Auth} from "./Auth";
import {SwitchLoader, LoaderType} from "../common";
import { withCookies, Cookies } from 'react-cookie';
import LoginForm from "./form/Login";
import TwoFectorForm from "./form/TwoFector";
import {ForgotPasswordForm} from "./form/ForgotPassword";
declare var window: any;

interface LoginSchema {
  email: string;
  password: string;
  remember: number;
  platform: string;
  admin: boolean;
  two_fector: boolean;
  forgot: boolean;
  my_device: boolean|number
}

const loginDefaultValue = {
  email: '',
  password: '',
  remember: 0,
  platform: 'web',
  admin: true,
  two_fector: false,
  my_device: false,
  forgot: false
}

interface IState{
  form: LoginSchema
}

class Login extends React.Component <Cookies, IState>{
  state : IState;

  constructor(props: any) {
    super(props);

    this.state ={
      form: loginDefaultValue,
    }
  }

  componentDidMount() {
    const { cookies } : any = this.props;
    this.setState({ form: { ...this.state.form, my_device: cookies.get("my_device")} })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.post(`${base_url}login`, this.state.form).then((res) => {
      if(!res.data.two_factor){
        Auth.authoriseLogin(res.data, this.props)
      }  else {
        this.setState({
          form: {
            ...this.state.form, two_fector: true
          }
        })
        Auth.setToken(res.data.token);
      }
    }).catch(e => console.log(e))
  }

  backToLogin = () => {
    this.setState({
      form: {
        ...this.state.form, two_fector: false, forgot: false
      }
    });
  }

  openForgotForm = () => {
    this.setState({
      form: {
        ...this.state.form, forgot: true
      }
    });
  }

  render() {
    const { cookies } : any = this.props;
    return(
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{backgroundImage: `url(${window.PUBLIC_URL}/admin/assets/media/bg/bg-3.jpg)` }}>
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container" style={{ height:"100vh"}}>
              <div className="kt-login__logo">
                <a className="login_logo" href="#">
                  <img src={`${window.PUBLIC_URL}/admin/assets/media/logos/LogoBlackLatoSquare@1.png`} height={125} width={'auto'} />
                </a>
              </div>
              {
                !this.state.form.forgot && !this.state.form.two_fector &&
                  <LoginForm {...this.state} handleSubmit={this.handleSubmit} handleChange={this.handleChange} handleForgot={this.openForgotForm}/>
              }

              {
                !this.state.form.forgot && this.state.form.two_fector &&
                <TwoFectorForm cookies={cookies} remember={this.state.form.remember} showLoginForm={this.backToLogin}/>
              }

              {
                this.state.form.forgot && !this.state.form.two_fector && 
                <ForgotPasswordForm showLoginForm={this.backToLogin}/>
              }

            </div>
          </div>
        </div>
      </div>
  </div>
    );
  }
}

export default withCookies(Login);
