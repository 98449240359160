import * as React from 'react';
import {LoaderType, showNotification, SubmitButton, SwitchLoader} from "../../common";
import Lang from "../../common/lang/Lang";
import API from "../../common/AxoisClient";
import {Auth} from "../Auth";
const moment = require("moment");

interface OtpSchema {
  otp: string;
  time: string;
  timer: number;
  is_timer: boolean,
}

export default class TwoFectorForm extends React.Component<any, OtpSchema>{
  interval:any;

  constructor(props: any) {
    super(props);

    this.state = {
      otp: '',
      timer: 60, // initial seconds to re-send otp button enable
      is_timer: true,
      time:"01:00"
    }

  }

  componentDidMount(): void {
    this.startTimer();
  }

  startTimer = () => {
    this.setState({
      is_timer: true
    });
    let counter = this.state.timer;
    this.interval = setInterval(() => {
      this.setState({
        time: moment().hour(0).minute(0).second(counter--).format('mm : ss')
      });
      if(counter < 0){
        this.setState({
          timer: this.state.timer + 60,
          is_timer: false
        })
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer = () => {
    clearInterval(this.interval);
  }

  componentWillUnmount(): void {
    this.clearTimer();
  }

  submitOtp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { cookies } : any = this.props;
    SwitchLoader(LoaderType.BUTTON);
    API.post(`auth-verify`, this.state).then((res) => {
      Auth.authoriseLogin(res.data, this.props)
    })
  }

  resendOtp = (event: React.InputHTMLAttributes<HTMLInputElement>) => {
    this.startTimer();
    API.post(`re-send-otp`).then((res) => {
      showNotification('success', res.data);
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target : any = event.target;
    const value :any = target.value;
    this.setState({ otp: value});
  }

  render(){
    const {  showLoginForm} = this.props;
    return (
      <div className="kt-login__signin">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Sign In To Admin</h3>
        </div>
        <form className="kt-form" method="POST" action="" id="login_form" onSubmit={this.submitOtp}>
          <div className={'kt-portlet__body'}>
            <div className="form-group ">
              <div className="input-group ">
                <input id="otp" type="password" placeholder="Enter OTP" value={this.state.otp}  onChange={this.handleChange} className="form-control" name="otp" required />
                <div className="input-group-append">
                  {
                    this.state.is_timer &&
                    <button  className="btn btn-primary" style={{height:45, marginTop:19}} type="button">{this.state.time}</button>
                  }
                  {
                    !this.state.is_timer &&
                    <button onClick={this.resendOtp} className="btn btn-primary" style={{height:45, marginTop:19}} type="button">Resend OTP</button>
                  }

                </div>
              </div>
            </div>
          </div>
          <div className="kt-login__actions">
            <button type={'button'} className="btn btn-brand btn-elevate kt-login__btn-secondary btn-warning" onClick={showLoginForm}>{Lang.back}</button> &nbsp;
            <SubmitButton className="btn btn-brand btn-elevate kt-login__btn-primary" label={Lang.submit}/>
          </div>
        </form>
      </div>
    )
  }
}