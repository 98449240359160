import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {AdminUserFormData} from "../../model";
import {RoleModel} from "../../model";
import {AdminUserValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common";
import {Select, SwitchLoader} from "../../common";
import {LoaderType} from "../../common";
import {UserService} from "../../services/UserService";

export class AdminUserCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      form: AdminUserFormData(),
      roles: [],
    }
    AdminUserValidations();
  }

  componentDidMount() {
    const ___that = this;
    async function init(){
      const roles = await UserService.getAdminRoles();
      ___that.setState({
        roles: roles
      })
    }
    init();
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#admin_user_form").valid()){
      API.post('users/admin-users', this.state.form).then((res) => {
        this.props.history.push(`/users/admin-users/edit/${res.data.id}/permissions`)
      })
    }
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_admin_user'}>
          <form className="kt-form" id={'admin_user_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'row'}>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.role} <span className={'required'}>*</span></label>
                    <Select onChange={this.handleChange} value={this.state.form.role_id} name={'role_id'} className="form-control">
                      <option value={''}>{Lang.select_role}</option>
                      {this.state.roles.map((item:RoleModel, index: number) => {
                        return <option value={item.id}>{item.name}</option>
                      })}
                    </Select>
                  </div>
                </div>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>First Name <span className={'required'}>*</span></label>
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.first_name} name={'first_name'} className="form-control" placeholder={"Enter first name"}/>
                  </div>
                </div>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>Last Name <span className={'required'}>*</span></label>
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.last_name} name={'last_name'} className="form-control" placeholder={"Enter last name"}/>
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.email} <span className={'required'}>*</span></label>
                    <input type={'email'} onChange={this.handleChange} value={this.state.form.email} name={'email'} className="form-control" placeholder={Lang.getString(`email_placeholder`)} />
                  </div>
                </div>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>Username <span className={'required'}>*</span></label>
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.username} name={'username'} className="form-control" placeholder={"Enter username"} />
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>{Lang.password} <span className={'required'}>*</span></label>
                    <input type={'password'} onChange={this.handleChange} value={this.state.form.password} name={'password'} className="form-control" placeholder={Lang.getString(`password_placeholder`)} id={'password'}/>
                  </div>
                </div>
               
                <div className={"col-md-6 col-lg-6"}>
                    <div className="form-group ">
                      <label>{Lang.telephone_number} <span className={'required'}>*</span></label>
                      <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no} name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)}/>
                    </div>
                </div>
              </div>
            </div>
            <CommonSubmitButton/>
          </form>
        </CreateLayout>
    )
  }
}