import { ResourceModel } from "../../model";

const ModuleName = 'Resources';

export const urls = {
  home: "/quiz/",
  list: "/quiz/resources",
}

export const ListBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: ModuleName,
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: ModuleName,
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label:ModuleName,
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export const CopyBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label:ModuleName,
    url: urls.list
  },
  {
    label: "Copy",
    url: '#'
  }
];

export interface IState {
  id: number;
  resource: ResourceModel;
  copy?: boolean;
}