import API from "../common/AxoisClient";
import { state_to_form_data } from "../helpers/UserHelper";
import { ResourceModel } from "../model";

export class ResourceService {

  static async get(id: number){
    const res = await API.get(`quiz/resources/${id}`);
    return res.data
  }


  static async store(data: ResourceModel) {
    const res = await API.post(`quiz/resources`, state_to_form_data(data));
    return res.data
  }

  static async update(data: ResourceModel, id: number){
    const res = await API.post(`quiz/resources/${id}`, state_to_form_data({...data, "_method": "put"}));
    return res.data
  }

  static async formData() {
    const res = await API.get(`quiz/resources/form-data`);
    return res.data
  }
}