import React from 'react';
import {Modal} from "react-bootstrap";
import { AnsFeedbackModel, ConceptModel } from '../../model';
import { SwitchLoader } from "./../../common/AxoisClient";
import { LoaderType, RenderForm, showNotification, SubmitButton } from '../../common';
import { AnsFeedbackService, QuestionnaireService } from '../../services';
import { MarkInput } from './form/MarkInputs';
import _ from 'lodash';

export const EditFeedbackModel = (props) => {

  const [form, setForm] = React.useState({} as AnsFeedbackModel);
  const [concept, setConcept] = React.useState([] as Array<ConceptModel>)

  const {onHide, feedback, age_groups, sports } = props;

  React.useEffect(() => {
    setForm(feedback);
  }, [feedback])


  React.useEffect(() => {
    async function init() {
      const concepts = await QuestionnaireService.getConceptByAgeGroup(form.age_group_id, form.sport_id);
      setConcept(concepts);
    }
    if(form.age_group_id > 0 &&  form.sport_id > 0) {
      init();
    }
  }, [form.age_group_id, form.sport_id])



  const handleSubmit = async (e) => {
    e.preventDefault();
    SwitchLoader(LoaderType.BUTTON)
    try {
      const res = await AnsFeedbackService.update(form, form.id);
      hideModel(true);
      showNotification("success", "Feedback information has been successfully updated.")
    } catch(e) {
      console.log(e);
    }
  }

  function hideModel(refresh?: boolean) {
    setForm({} as AnsFeedbackModel)
    onHide(refresh);
  }

  function onChange(e) {
    const { name, value } = e.target;
    const newForm: any = {...form};
    newForm[name] = value;
    if(name === 'age_group_id' || name === 'sport_id') {
      newForm['concept_id'] = '';
    }
    setForm(newForm);
  }
  const FormInputs = [
    {
      "name" : "age_group_id",
      "value" : _.get(form, "age_group_id", ""),
      "onChange": onChange,
      "required": true,
      "type": "select",
      "placeholder": "Choose age group",
      "label": "Age group",
      "options": age_groups.slice(1),
      "readonly": props.id !== undefined
    },
    {
      "name" : "sport_id",
      "value" : _.get(form, "sport_id", ""),
      "onChange": onChange,
      "required": true,
      "type": "select",
      "placeholder": "Choose sport",
      "label": "Sport",
      "options": sports.slice(1),
      "readonly": props.id !== undefined
    },
    {
      "name" : "concept_id",
      "value" : _.get(form, "concept_id", ""),
      "onChange": onChange,
      "required": true,
      "type": "select",
      "placeholder": "Choose Concept",
      "label": "Concept",
      "options": concept,
      "readonly": props.id !== undefined
    },
  ];
  
  function isDisable() {
    const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
    return fields;
  }

  return(
    <Modal
      {...props}
      onHide={hideModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form  onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
            <MarkInput form={form} onChange={onChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton disabled={isDisable()} className={"btn btn-primary"} label={"Submit"}/>
        </Modal.Footer>
      </form>
    </Modal>
  )
};
