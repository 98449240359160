import API from "../common/AxoisClient";
import {state_to_form_data} from "../helpers/UserHelper";

export class PillarService {

  /**
   * get pillers list
   */
  static async getPillarInfo(id: number){
    const res = await API.get(`pillars/pillars/${id}`);
    return res.data
  }

  /**
   * get Competence list
   */
  static async getCompetenceList(){
    const res = await API.get(`pillars/pillars/competence`);
    return res.data
  }

  /**
   * get getConcept
   */
  static async getConcept(id: number){
    const res = await API.get(`pillars/concepts/${id}`);
    return res.data
  }

  static async storeConcept(form) {
    const store = await API.post("pillars/concepts", state_to_form_data(form));
    return  store.data;
  }

  static async updateConcept(form, id) {
    const store = await API.post(`pillars/concepts/${id}`, state_to_form_data({...form, '_method': 'PUT'}));
    return  store.data;
  }

}