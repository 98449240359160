import React from 'react';
import {Modal} from "react-bootstrap";
import {DatePickerWrapper, LoaderType, showNotification, SubmitButton, SwitchLoader} from "../../../common";
import {ClubSessionModel} from "../../../model";
import API from "./../../../common/AxoisClient"
import moment from "moment";
import { humanifyDate } from '../../../helpers/UserHelper';

export const QuestionnaireSessionModel = (props) => {

  const [form, setForm] = React.useState([] as Array<ClubSessionModel>);
  const {club, onHide, show} = props;


  React.useEffect(() => {
    API.get(`clubs/clubs/${club}/session`).then(res => {
      const {data} = res;
      setForm(data);
    }).catch(e => {})
  }, [club, show])
 
  function hideModel() {
    setForm([] as Array<ClubSessionModel>)
    onHide();
  }
  
  return(
    <Modal
      {...props}
      onHide={hideModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Questionnaire Sessions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SessionTable form={form} />
      </Modal.Body>
    </Modal>
  )
};

const SessionTable = ({form}) => {
  const InputDateFormat =  'yyyy-MM-DD';
  function activeSession(item: ClubSessionModel) {
    const currentDate = new Date(); // Get the current date and time
    const startDate = new Date(item.start_date);
    const endDate = new Date(item.end_date);
    return currentDate >= startDate && currentDate <= endDate
  }
  if(form.length <= 0) {
    return (<p>Please wait...</p>)
  }
  return ( 
    <table className='table borderd'>
      <tr>
        <th>Session No</th>
        <th>Start Date</th>
        <th>End Date</th>
      </tr>
      {
        form.map((item: ClubSessionModel, index: number) => {
          const isActive = activeSession(item);
          return <tr key={item.id} className={isActive === true ? 'table-primary': ''}>
            <td>{index+1}</td>
            <td>{humanifyDate(item.start_date, InputDateFormat)}</td>
            <td>
              {humanifyDate(item.end_date, InputDateFormat)}
              {isActive === true ? <span style={{float: 'right'}} className='badge btn-brand'>Active</span>: ''}
            </td>
          </tr>
        })
      }
    </table>
  )
}