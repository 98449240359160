import * as React from 'react';
import {CONST, getQueryStringParams, SearchInput, SearchSelect} from "../../common";
import { QuestionnaireService } from '../../services';
import { ConceptModel } from '../../model';

interface IState {
  concepts: [];
  form: {
    like: {
      title: string,
    },
    equal: {
      status: number,
      sport_id: number,
      role_id: number,
      age_group_id: number,
      concept_id: number,
    }
  },
 }

export default class Filters extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          title: old_data ? old_data.like.title : '',
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          sport_id: old_data ? old_data.equal.sport_id : '',
          role_id: old_data ? old_data.equal.role_id : '',
          age_group_id: old_data ? old_data.equal.age_group_id : '',
          concept_id: old_data ? old_data.equal.concept_id : '',
        }
      }}, () => this.loadConcepts())
  }
   
  async loadConcepts() {
    const { form: { equal : { age_group_id, sport_id } } } = this.state;
    if(age_group_id > 0 && sport_id > 0) {
      const allConcepts = await QuestionnaireService.getConceptByAgeGroup(age_group_id, sport_id);
      const concepts = allConcepts.map((con:ConceptModel) => ({...con, label: con.name, value: con.id }));
      this.setState({concepts});
    }
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = { ...this.state.form };
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  {
      this.props.getResults(this.state.form)
      if(name[2] === 'age_group_id' || name[2] === 'sport_id') {
        this.loadConcepts();
      }
    });    
  }

  prepareSelectData(data, placeholder) {
    return (data === undefined || data.length <= 0 ) ? [] : [{"label": placeholder, "value": ""}, ...data];
  }
  render(){
  
    const { ageGroups, roles, sports } = this.props;
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.title'} value={this.state.form.like.title} onChange={this.handleInputForm} placeholder={'Search Resources..'} />
        <SearchSelect name={'form.equal.role_id'} value={this.state.form.equal.role_id}  onChange={this.handleInputForm} options={this.prepareSelectData(roles, 'Select Role')}/>
        <SearchSelect name={'form.equal.sport_id'} value={this.state.form.equal.sport_id}  onChange={this.handleInputForm} options={this.prepareSelectData(sports, 'Select Sport')}/>
        <SearchSelect name={'form.equal.age_group_id'} value={this.state.form.equal.age_group_id}  onChange={this.handleInputForm} options={this.prepareSelectData(ageGroups, 'Select Age group')}/>
        <SearchSelect name={'form.equal.concept_id'} value={this.state.form.equal.concept_id}  onChange={this.handleInputForm} options={this.prepareSelectData(this.state.concepts, 'Select Concepts')}/>
        <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={CONST.status}/>
      </div>
    )
  }
}