import * as React from 'react';
import {getQueryStringParams, SearchInput, SearchSelect} from "../../common";
import { QuestionnaireService } from '../../services';

interface IState {
  form: {
    like: {
      feedback: string,
    },
    equal: {
      status: number,
      age_group_id: number,
      sport_id: number,
      concept_id: number
    }
  },
  concepts: Array<any>
 }

export default class Filters extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({
      form:  {
        like: {
          feedback: old_data ? old_data.like.feedback : '',
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          age_group_id: old_data ? old_data.equal.age_group_id : '',
          sport_id: old_data ? old_data.equal.sport_id : '',
          concept_id: old_data ? old_data.equal.sport_id : '',
        }
      },
      concepts: [{ label: "Select Concepts", value: "" }]
    })
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      form: newAccess
    }),() =>  {
      if (name[2] === 'age_group_id' || name[2] === 'sport_id' ) {
        this.init();
      }
      this.props.getResults(this.state.form)
    });
  }

  async init() {
    const { age_group_id, sport_id } = this.state.form.equal;
    if(age_group_id > 0 && sport_id > 0) {
      const concepts = await QuestionnaireService.getConceptByAgeGroup(age_group_id, sport_id);
      this.setState({ concepts: [{ label: "Select Concepts", value: "" }, ...concepts] }); 
    }
  }
  render(){
    const {age_groups, sports} = this.props;
    const { concepts } = this.state;
    const newConcepts = concepts.length > 1 ? concepts.map(e => ({label: e.name, value: e.id})) : concepts;
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.feedback'} value={this.state.form.like.feedback} onChange={this.handleInputForm} placeholder={'Search feedback..'} />
        <SearchSelect name={'form.equal.sport_id'} value={this.state.form.equal.sport_id}  onChange={this.handleInputForm} options={sports}/>
        <SearchSelect name={'form.equal.age_group_id'} value={this.state.form.equal.age_group_id}  onChange={this.handleInputForm} options={age_groups}/>
        <SearchSelect name={'form.equal.concept_id'} value={this.state.form.equal.concept_id}  onChange={this.handleInputForm} options={newConcepts}/>
      </div>
    )
  }
}