import * as React from 'react';
import {CONST, getQueryStringParams, SearchInput, SearchSelect} from "../../common";

interface IState {
  form: {
    like: {
      name: string,
    },
    equal: {
      status: number
    }
  },
 }

export default class Filters extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          name: old_data ? old_data.like.name : '',
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
        }
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

  render(){
    const {age} = this.props;
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.name'} value={this.state.form.like.name} onChange={this.handleInputForm} placeholder={'Search sport..'} />
        <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={CONST.status}/>
      </div>
    )
  }
}