import React, {useEffect, useState} from 'react';
import {Breadcrumb, Grids} from "../common";
import Lang from "../common/lang/Lang";
import {get_total_clubs} from "../services";


export function ClubGrid (){
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(true);

  useEffect(() => {
    get_total_clubs().then(res => {
      setData(res);
      setWait(false);
    })
  }, []);


  return(
    <div>
      <Breadcrumb data={[{
        label: "Clubs",
        url: "#"
      }]}/>

      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        {wait &&
          <div className={'kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded'}>
            <div className="alert alert-solid-brand" role="alert">
              <div className="alert-icon"><i className="flaticon-warning"/></div>
              <div className="alert-text">{Lang.please_wait}</div>
            </div>
          </div>
        }
        {!wait &&
          <Grids data={data} />
        }

      </div>
    </div>
  );
}
