import * as React from 'react';
import {Breadcrumb} from "../common/page/Breadcrumb";
import Localization from "../common/lang/Lang";
import _ from 'lodash';

interface Prop {
  breadcrumb: any;
  children?: React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;
}

interface ListFormProps {
  form: any;
  type?: any;
  table: any
}

interface ListHeaderProps {
  order_no?: number;
  title: string;
  icon: string;
  className?: string;
  children?: React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;
}

export const ListingLayout = (props: Prop) => {
  return (
    <div>
      <Breadcrumb data={props.breadcrumb}/>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export const ListHeader = (props: ListHeaderProps) => {
  const {title, icon, children, className, ...rest} = props;
  return(
    <div className={`kt-portlet__head kt-portlet__head--lg ${className}`} {...rest}>
      <div className="kt-portlet__head-label">
        <span className="kt-portlet__head-icon">
            <i className={`kt-font-brand ${icon}`}/>
        </span>
        <h3 className="kt-portlet__head-title">
          {Localization.getString(title) || title}
        </h3>
      </div>
      <div className="kt-portlet__head-toolbar">
        <div className="kt-portlet__head-wrapper">
          <div className="kt-portlet__head-actions">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export const ListForm = (props: ListFormProps) => {
  return(
    <form method={'get'} id={'filter_form'}>
      <div className="kt-portlet__body" style={{paddingTop:0, paddingBottom:0}}>
        <div className="kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10">
          <div className="row align-items-center">
            <div className="col-xl-12 order-2 order-xl-1">
                {props.form}
            </div>
          </div>
        </div>
      </div>
      {props.table}
    </form>
  );
}