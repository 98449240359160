import React from "react";
import _, { get } from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {IState} from "../Data";
import { ResourceService, QuestionnaireService } from "../../../services";
import { ResourceModel, ConceptModel } from "../../../model";
import { CropImage } from "../../../clubs/clubs/modal/CropImage";

export const ResourceForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as ResourceModel);
  const [concept, setConcept] = React.useState([] as Array<ConceptModel>)
  const [formData, setFormData] = React.useState({});
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    async function init() {
      const fdata = await ResourceService.formData();
      setFormData(fdata);
    }
    init()
  },[])

  React.useEffect(() => {
    async function init() {
      const concepts = await QuestionnaireService.getConceptByAgeGroup(form.age_group_id, form.sport_id);
      setConcept(concepts);
    }
    if(form.age_group_id > 0 &&  form.sport_id > 0) {
      init();
    }
  }, [form.age_group_id, form.sport_id])

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value, type } = target;
    if(type === 'file') {
      setForm({...form, [name]: target.files[0]});
    } else {
      setForm({...form, [name]: value});
    }
    if(name === 'cover_crop_file') {
      setShow(true);
    }
  }

  const handleTags = (value) => {
    setForm({...form, tags : value})
  }

  const handleEditorChange = (message : any, editor : any) => {
    setForm({...form, description: message})
  }

  React.useEffect(() => {
    if(props.copy !== undefined && props.copy === true) {
      const { resource } = props;
      setForm({...resource, file: '', res_file: '', cover_file: ''} as any)
    } else {
      setForm(props.resource)
    }
    
  },[props.resource])


    const FormInputs = [
      {
        "name" : "role_id",
        "value" : _.get(form, "role_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose Role",
        "label": "Role",
        "options": _.get(formData, "roles", []),
      },
      {
        "name" : "age_group_id",
        "value" : _.get(form, "age_group_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose age group",
        "label": "Age group",
        "options": _.get(formData, "ageGroups", []),
      },
      {
        "name" : "sport_id",
        "value" : _.get(form, "sport_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose sport",
        "label": "Sport",
        "options": _.get(formData, "sports", []),
      },
      {
        "name" : "concept_id",
        "value" : _.get(form, "concept_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose Concept",
        "label": "Concept",
        "options": concept,
      },
      {
        "name" : "title",
        "value" : _.get(form, "title", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter title",
        "label": "Title",
      },
      {
        "name" : "tags",
        "value" : _.get(form, "tags", ""),
        "onChange": handleTags,
        "required": true,
        "placeholder": "Enter tags",
        "type": "tags",
        "label": "Tags",
        "options": _.get(formData, "tags", []),
        'isMulti': true,
      },
      {
        "name" : "file_type",
        "value" : _.get(form, "file_type", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Select file type",
        "label": "File Type",
        "options":  _.get(formData, "types", []),
      },
      {
        "name" : "cover_crop_file",
        "onChange": handleChange,
        "type": 'file',
        "label": "Cover Image",
        "file_path": _.get(form, "cover_image", ''),
      },
      {
        "name" : "res_file",
        "onChange": handleChange,
        "required": ['IMAGE', 'PDF', 'AUDIO'].indexOf(_.get(form, "file_type", null)) > -1,
        "type": 'file',
        "label": "Resource File",
        "extra": form.file !== undefined && form.file !== '' ? <div><a target="_blank" rel="noopener noreferrer" href={_.get(form, "file", 'javascript:void(0)')}>Resource File</a></div>: null,
        "show": ['IMAGE', 'PDF', 'AUDIO'].indexOf(_.get(form, "file_type", null)) > -1
      },
      {
        "name" : "file",
        "value" : _.get(form, "file", ""),
        "onChange": handleChange,
        "required": ['VIDEO'].indexOf(_.get(form, "file_type", null)) > -1,
        "label": "Embeded code",
        "placeholder": "Enter Resource Embeded code",
        "type": 'textarea',
        "show": ['VIDEO'].indexOf(_.get(form, "file_type", null)) > -1
      },
     
      {
        "name" : "description",
        "value" : _.get(form, "description", ""),
        "onEditorChange": handleEditorChange,
        "required": true,
        "placeholder": "Enter description",
        "type": "editor",
        "label": "Description",
        "parent_class": 'col-md-12 col-lg-12'
      },
    ];

    function isDisable() {
      const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
      return fields;
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id && props.copy === undefined){
      ResourceService.update(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/resources`)
      }).catch(() => {});
    } else {
      ResourceService.store(form).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/resources`)
      }).catch(() => {});
    }
  }

  return(
      <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
          </div>
        </div>
        <CommonSubmitButton disabled={isDisable()}/>
        <CropImage
            show={show}
            file={get(form, "cover_crop_file", "")}
            type={'logso'}
            ratio={19/10}
            closeModal={(file) => {
              setShow(false);
              if(file !== undefined ) {
                setForm({...form, 'cover_image': file } as any) 
              }
            }}
          />
      </form>
    )
}
