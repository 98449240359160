import _ from 'lodash';
import * as React from 'react';
import { getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
import { TeamModel } from '../../../model';

interface IState {
  form: {
    like: {
      search: string,
      email: string
    },
    equal: {
      status: number,
      role_id: number;
      age_group_id: number;
    },
    custom: {
      team_id: number,
      club_id: number;
    }
  },
  teams: Array<any>
 }

export default class CustomerFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          search: old_data ? old_data.like.search : '',
          email: old_data ? old_data.like.email : ''
        },
        equal: {
          status: old_data ? old_data.equal.status : '',
          role_id: old_data ? old_data.equal.role_id : '',
          age_group_id: old_data ? old_data.equal.age_group_id : '',
        },
        custom: {
          club_id: old_data ? old_data.custom.club_id : '',
          team_id: old_data ? old_data.custom.team_id : '',
        }
      },
      teams: []
    })
  }



  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

  getTeams = (clubs, club_id) => {
    const club = clubs.filter(item => item.id == club_id);
    return _.get(club, "[0].teams", []);
  }
 

  render(){
    const {status, roles, age_groups, clubs} = this.props;
    const teams = [{"label": "Select Team", "value": ""}, ...this.getTeams(clubs, this.state.form.custom.club_id)];
    return (
      <div className="row align-items-center">
        <SearchInput name={'form.like.search'} value={this.state.form.like.search} onChange={this.handleInputForm} placeholder={'Search User..'} />
        <SearchSelect name={'form.custom.club_id'} value={this.state.form.custom.club_id}  onChange={this.handleInputForm} options={clubs}/>
        <SearchSelect name={'form.custom.team_id'} value={this.state.form.custom.team_id}  onChange={this.handleInputForm} options={teams}/>
        <SearchSelect name={'form.equal.role_id'} value={this.state.form.equal.role_id}  onChange={this.handleInputForm} options={roles}/>
        <SearchSelect name={'form.equal.age_group_id'} value={this.state.form.equal.age_group_id}  onChange={this.handleInputForm} options={age_groups}/>
        <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status}  onChange={this.handleInputForm} options={status}/>
      </div>
    )
  }
}