import React from "react";
import { get, isEmpty, isNull } from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import {ClubSettingsModel} from "../../../model";
import {CSState, PRIMARY_BUTTON, SECONDARY_BUTTON} from "../Data";
import {ClubService} from "../../../services";
import { ThemeButtonForm } from "./ThemeButtonForm";
import { CropImage } from "../modal/CropImage";

export const ClubSettingForm = (props: CSState) => {
  const [form, setForm] = React.useState({} as ClubSettingsModel);
  const [show, setShow] = React.useState(false);
  const [file, setFile] = React.useState({});

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0]  : target.value;
    const name = target.name;
    if(target.type === 'color') {
      const nstdName = name.split(".");
      setForm({...form, 
        ...{ [nstdName[0]]: {...form[nstdName[0]], ...{ [nstdName[1]]: value }  } }
      });
    } else {
      setForm({...form, [name]: value})
    }

    if(target.type === 'file') {
      setShow(true)
    }
  }

  React.useEffect(() => {
    if(!isEmpty(props.clubSetting)) {
      setForm(props.clubSetting)
    } else {
      setForm({...form, ...{
        primary_button: PRIMARY_BUTTON,
        secondary_button: SECONDARY_BUTTON
      }});
    }
    
  },[props.clubSetting])


  const FormInputs = [
    
    {
      "name" : "bg_file",
      "onChange": handleChange,
      "type": "file",
      "label": "Company BG Image",
      "file_path":  get(form, "bg_img", ""),
      "required" : true,
      'value': ''
    },
  ];

  const ColorInpWid = 80;

  const ColorInputs = [
    {
      "button": 'Primary',
      "group": 'primary_button',
      "fields" : [
        {
          "name" : "primary_button.bg_color",
          "value" : get(form, "primary_button.bg_color", PRIMARY_BUTTON.bg_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Backgroud Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "primary_button.hover_color",
          "value" : get(form, "primary_button.hover_color", PRIMARY_BUTTON.hover_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Hover Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "primary_button.text_color",
          "value" : get(form, "primary_button.text_color", PRIMARY_BUTTON.text_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Text Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "primary_button.active_color",
          "value" : get(form, "primary_button.active_color", PRIMARY_BUTTON.active_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Active Color",
          "style": {width: ColorInpWid}
        },
      ]
    },
    {
      "button": 'Secondary',
      "group": 'secondary_button',
      "fields" : [
        {
          "name" : "secondary_button.bg_color",
          "value" : get(form, "secondary_button.bg_color", SECONDARY_BUTTON.bg_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Backgroud Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "secondary_button.hover_color",
          "value" : get(form, "secondary_button.hover_color", SECONDARY_BUTTON.hover_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Hover Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "secondary_button.text_color",
          "value" : get(form, "secondary_button.text_color", SECONDARY_BUTTON.text_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Text Color",
          "style": {width: ColorInpWid}
        },
        {
          "name" : "secondary_button.active_color",
          "value" : get(form, "secondary_button.active_color", SECONDARY_BUTTON.active_color),
          "onChange": handleChange,
          "required": true,
          "type": 'color',
          "label": "Active Color",
          "style": {width: ColorInpWid}
        },
      ]
    }

  ];


  function isDisable() {
    return form['bg_file'] === undefined && props.clubSetting.id === undefined;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.clubSetting.id){
      ClubService.updateClubSettings(form, props.id, props.clubSetting.id).then((message) => {
        showNotification("success", message);
        props.handleSuccess();
      }).catch(() => {});
    } else {
      ClubService.storeClubSettings(form, props.id).then((message) => {
        showNotification("success", message);
        props.handleSuccess();
      }).catch(() => {});
    }
  }

    return(
      <form className="kt-form" id={'club_theme_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
          </div>
          <ThemeButtonForm ColorInputs={ColorInputs} />
        </div>
        <CommonSubmitButton disabled={isDisable()} />
        <CropImage
          show={show}
          file={get(form, "bg_file", "")}
          type={'bg_img'}
          closeModal={(file) => {
            setShow(false);
            setForm({...form, 'bg_img': file } as any)
          }}
        />
      </form>
    )
}