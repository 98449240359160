import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {QuestionnaireTypeModel} from "../../../model";
import {IState} from "../Data";
import { QuesTypeService} from "../../../services";

export const CreateForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as QuestionnaireTypeModel);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]: value})
  }

  const handleEditorChange = (message : any, editor : any) => {
    setForm({...form, description: message})
  }
  React.useEffect(() => {
    setForm(props.form)
  },[props.form])

  

    const FormInputs = [
      {
        "name" : "title",
        "value" : _.get(form, "title", ""),
        "onChange": handleChange,
        "required": true,
        "placeholder": "Enter title",
        "label": "Title",
        "parent_class": 'col-md-12 col-lg-12',
      },
      {
        "name" : "description",
        "value" : _.get(form, "description", ""),
        "onEditorChange": handleEditorChange,
        "required": true,
        "placeholder": "Enter description",
        "label": "Description",
        "type": 'editor',
        "parent_class": 'col-md-12 col-lg-12',
      },
    ];

    function isDisable() {
      const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
      return fields;
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id ){
      QuesTypeService.update(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/ques-types`)
      }).catch(() => {});
    } else {
      QuesTypeService.store(form).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/ques-types`)
      }).catch(() => {});
    }
  }

  return(
      <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
          </div>
        </div>
        <CommonSubmitButton disabled={isDisable()}/>
      </form>
    )
}
