import React from "react";
import _ from "lodash";
import {IFeedbackInput} from "../../../model";
import {renderOptions, Select, ansRange, Icons} from "../../../common";
import { MarkInput } from "./MarkInputs";

const AnsRange = ansRange();

export function Feedbacks({form, remove, onChange}) {

  function ActionButton({index}) {
    return index === 0 ? null:
      <button type={"button"} onClick={() => remove(index)} style={{marginTop:25}} className={"btn btn-danger btn-icon"}><i className={"fa fa-minus"}/></button>
  }

  const feedbacks = _.get(form, "feedbacks", []);

  if(feedbacks){
    return feedbacks.map((question: IFeedbackInput, index: number) => <MarkInput key={index} form={question} onChange={onChange(index)} remove={ <ActionButton index={index}/>} />);
    return feedbacks.map((question: IFeedbackInput, index: number) => {
      return  <div className={"col-md-12 col-lg-12"} key={index} style={{borderBottom: "1px solid #d2d3d4", marginTop:5}}>
        <div className={ "row"}>
          <div className={ "col-md-12 col-lg-12"}>
            <div className="form-group">
              <label>Feedback</label>
              <textarea required={true} value={question.feedback || ""} onChange={onChange(index)} className={'form-control'} name={"feedback"} placeholder={`Enter feedback`}/>
            </div>
          </div>
          
          <div className={ "col-md-12 col-lg-12"}>
            <div className={'row'}>
              <div className={ "col-md-5 col-lg-5"}>
                <div className="form-group">
                  <label>Statement Range From</label>
                  <Select required={true} onChange={onChange(index)}  className={'form-control'} name={"min_marks"} value={question.min_marks || ""}>
                    <option value={''}>Select Range</option>
                    {renderOptions(AnsRange)}
                  </Select>
                </div>
              </div>
              <div className={ "col-md-5 col-lg-5"}>
                <div className="form-group">
                  <label>Statement Range To</label>
                  <Select required={true} onChange={onChange(index)}  className={'form-control'} name={"max_marks"} value={question.max_marks || ""}>
                    <option value={''}>Select Range</option>
                    {renderOptions(AnsRange)}
                  </Select>
                </div>
              </div>
              <div className={ "col-md-1 col-lg-1"}>
                <ActionButton index={index}/>
              </div>
            </div>
            <div className={"row feedback-warning"}>
              <i className={`kt-menu__link-icon ${Icons.view}`}/> 
               &nbsp; Please make sure the range does not conflicts with other feedbacks statement range
            </div>
          </div>
        </div>
      </div>
    })
  }
  return null;
}
