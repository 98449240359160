import {AnsFeedbackModel} from "../../model";

export const urls = {
  home: "/quiz/",
  list: "/quiz/ans-feedback",
}

export const ListBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Feedback",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Feedback",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Feedback",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number;
  feedback: AnsFeedbackModel;
}