import * as React from "react";
import {SelectOptions} from "../";


export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export const  LimitMetaDefaultValue = {
  current_page: 0,
  from: 0,
  last_page: 0,
  path: "",
  per_page: 0,
  to: 0,
  total: 0
};


export interface PaginationSchema {
  totalPage: number;
  totalRecods: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  recordLimit?: Array<SelectOptions>
  perPage: number,
  from: number,
  to: number,
  onLimitChange: (limit: number) => void;
  forcePage?: number;
}


export class Limit extends React.Component<PaginationSchema> {
  static defaultProps = {
    marginPagesDisplayed: 2,
    pageRangeDisplayed:5,
    recordLimit:[{label: 10, value: 10}, {label: 20, value: 20},{label: 30, value: 30}, {label: 50, value: 50}, {label: 100, value: 100}]
  };

  componentDidMount(){
    ($(".selectpicker") as any).selectpicker();
    const __this = this;
    $('#limit_holder').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      let limit = Number(__this.props.recordLimit[clickedIndex-1].value);
      __this.props.onLimitChange(limit)
    });
  }


  render(){
    return (
      <div className="kt-datatable__pager-info">
        <div className="dropdown bootstrap-select kt-datatable__pager-size" style={{width: 80}}>
          <select id={'limit_holder'} className="selectpicker kt-datatable__pager-size" title="Select page size" data-width="60px"
                  data-selected={this.props.perPage} tabIndex={-98}
                  defaultValue={`${this.props.perPage}`}>
            {this.props.recordLimit && this.props.recordLimit.map((item : SelectOptions, index: number) => {
              return <option value={item.value} key={index}>{item.label}</option>
            })}
          </select>
        </div>
        &nbsp;
        <span className="kt-datatable__pager-detail" style={{fontSize: 15}}>Showing {this.props.from} - {this.props.to} of {this.props.totalRecods}</span>
      </div>
    )
  }
}
