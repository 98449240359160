import React from "react";
import _ from "lodash";
import {ConceptModel, QuestionnaireStatement} from "../../../model";
import {renderOptions, Select} from "../../../common";
import {QuestionnaireService} from "../../../services";

export function Questionnaires({form, remove, onChange, competence, ageGroup}) {

  const [concept, setConcept] = React.useState([] as Array<ConceptModel>)

  React.useEffect(() => {
    async function init() {
      const concepts = await QuestionnaireService.getConceptByAgeGroup(ageGroup, form.sport_id);
      setConcept(concepts);
    }

    init();
  }, [ageGroup, form.sport_id])



  function ActionButton({index}) {
    return index === 0 ? null:
      <button type={"button"} onClick={() => remove(index)} style={{marginTop:25}} className={"btn btn-danger btn-icon"}><i className={"fa fa-minus"}/></button>
  }

  const questionnaire_statements = _.get(form, "questionnaire_statements", []);

  if(questionnaire_statements){
    return questionnaire_statements.map((question: QuestionnaireStatement, index: number) => {
      return  <div className={"col-md-12 col-lg-12"} key={index} style={{borderBottom: "1px solid #d2d3d4", marginTop:5}}>
        <div className="form-group" style={{marginBottom:5}}>
          <label><strong>Question {index+1}</strong></label>
        </div>
        <div className={ "row"}>
          <div className={ "col-md-12 col-lg-12"}>
            <div className="form-group">
              <label>Question</label>
              <textarea required={true} value={question.question || ""} onChange={onChange(index)} className={'form-control'} name={"question"} placeholder={`Enter question ${index+1}`}/>
            </div>
          </div>
          <div className={ "col-md-12 col-lg-12"}>
            <div className={"row"}>
              <div className={ "col-md-4 col-lg-4"}>
                <div className="form-group">
                  <label>Select Competence</label>
                  <Select required={true} onChange={onChange(index)}  className={'form-control'} name={"competence_id"} value={question.competence_id || ""}>
                    <option value={0}>Select Competence</option>
                    { renderOptions(competence)}
                  </Select>
                </div>
              </div>
              <div className={ "col-md-4 col-lg-3"}>
                <div className="form-group">
                  <label>Select Concept</label>
                  <Select required={true} onChange={onChange(index)}  className={'form-control'} name={"concept_id"} value={question.concept_id || ""}>
                    <option value={0}>Select Concept</option>
                    { renderOptions(concept)}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className={ "col-md-12 col-lg-12"}>
            <div className={'row'}>
              <div className={ "col-md-3 col-lg-3"}>
                <div className="form-group">
                  <label>Statement first</label>
                  <input type={"text"} required={true} onChange={onChange(index)} className={'form-control'} name={"statement_1"} value={question.statement_1 || ""} placeholder={`Enter first statement`}/>
                </div>
              </div>
              <div className={ "col-md-3 col-lg-3"}>
                <div className="form-group">
                  <label>Statement second</label>
                  <input type={"text"} required={true} onChange={onChange(index)} className={'form-control'} name={"statement_2"} value={question.statement_2 || ""} placeholder={`Enter second statement`}/>
                </div>
              </div>
              <div className={ "col-md-3 col-lg-3"}>
                <div className="form-group">
                  <label>Statement third</label>
                  <input type={"text"} required={true} onChange={onChange(index)} className={'form-control'} name={"statement_3"} value={question.statement_3 || ""} placeholder={`Enter third statement`}/>
                </div>
              </div>
              <div className={ "col-md-2 col-lg-2"}>
                <div className="form-group">
                  <label>Statement fourth</label>
                  <input type={"text"} required={true} onChange={onChange(index)} className={'form-control'} name={"statement_4"} value={question.statement_4 || ""} placeholder={`Enter fourth statement`}/>
                </div>
              </div>
              <div className={ "col-md-1 col-lg-1"}>
                <ActionButton index={index}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    })
  }
  return null;
}
