import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import API from "../../../common/AxoisClient";
import { useHistory } from "react-router-dom";
import {PillarModel} from "../../../model";
import {IState} from "../Data";

export const PillarForm = (props: IState) => {
    let history = useHistory();
    const [form, setForm] = React.useState({} as PillarModel);

    const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
        const target : any = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm({...form, [name]: value})
    }

    React.useEffect(() => {
      setForm(props.pillar)
    },[props.pillar])
  
    const handleEditorChange = (message : any, editor : any) => {
      setForm({...form, description: message})
    }

    const FormInputs = [
        {
            "name" : "name",
            "value" : _.get(form, "name", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter pillar name",
            "label": "Pillar name",
        },
        {
            "name" : "color_code",
            "value" : _.get(form, "color_code", ""),
            "onChange": handleChange,
            "required": true,
            "placeholder": "Enter color_code",
            "type": "color",
            "label": "Color code"
        },
        {
            "name" : "description",
            "value" : _.get(form, "description", "Hello"),
            "onEditorChange": handleEditorChange,
            "required": true,
            "placeholder": "Enter description",
            "type": "editor",
            "label": "Description",
            "parent_class": "col-md-12 col-lg-12"
        },
    ];

    function isDisable() {
      return FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.put(`pillars/pillars/${props.id}`, form).then((res) => {
      showNotification("success", res.data.message);
      history.push(`/pillars/pillars`)
    }).catch(() => {})
  }

    return(
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
      )
}