import * as React from 'react';
import {CreateBreadcrumb} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {CommonSettingValues} from "../../model/CommonSetting";
import {RouteComponentProps} from "react-router";
import {CommonValidations} from "./Validation";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton, SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";



declare var window: any;

interface IState{
  form: {
  
    setting_name : string,
    setting_value: string,
};
 id:number;
 
}

export class CommonCreate extends React.Component<RouteComponentProps, IState> {
  state: IState;
  
  constructor(props: any){
    super(props);

    

    this.state = {
      form: CommonSettingValues(),
     
      id: props.match.params.id,
      
    }
    CommonValidations();
     
  }





handleChange = async (event: React.ChangeEvent<HTMLElement>) => {
      const target : any = event.target;
      let value =  target.value;
      const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  
    }
  


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
     SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
    API.post('/settings/common-setting', this.state.form).then((res) => {
      this.props.history.push(`/settings/common-setting`) 
    })
  }   
  }
  
  render() {
     
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'common_setting'}>
           
            <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
                <div className="kt-portlet__body">
                    <div className={'row'}>
                   <div className={"col-md-12 col-lg-12"}>
                   
                    <div className="form-group">
                                <label>{Lang.setting_name} <span className={'required'}>*</span></label>
                                <input type={'text'} onChange={this.handleChange}  name={'setting_name'} className="form-control"/>
                              </div>
                    
                              <div className="form-group">
                                <label>{Lang.setting_value} <span className={'required'}>*</span></label>
                                <textarea onChange={this.handleChange}   name={'setting_value'} className="form-control"/>
                              </div>
                    </div>
                    
                </div>
                </div>
     <CommonSubmitButton/>
            </form>
        </CreateLayout>
   
   )
  }
}