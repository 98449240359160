import * as React from 'react';
import { ImportBreadcrumb, ImportIState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {base_url, CommonSubmitButton, showNotification} from "../../common";
import API from './../../common/AxoisClient';
import {state_to_form_data} from "../../helpers/UserHelper";

export class CustomerImport extends React.Component<RouteComponentProps, ImportIState> {
  state: ImportIState;
  constructor(props:any){
    super(props);
  }

  componentDidMount() {
    const {match: {params} }= this.props;
    const {id}: any = params;
    this.setState({
      id: id
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    API.post("clubs/teams/import", state_to_form_data(this.state)).then(res => {
      if(res.data.status === false) {
        showNotification("danger", res.data.errors);
      }
      showNotification("success", res.data.message);
    }).catch((error) => {
      console.log(error);
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0]  : target.value;
    this.setState({
      file:  value
    })
  }

  render() {
    return (
        <CreateLayout breadcrumb={ImportBreadcrumb} title={'Import players'}>
          <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'row'}>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>File  <span className={'required'}>*</span>
                    </label>
                    <div className="custom-file">
                      <input onChange={this.handleChange} className="custom-file-input" type={"file"} name={"import_file"} required={true}  />
                      <label className="custom-file-label" htmlFor="customFile"/>
                    </div>
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group"/>
                  <div className={"form-field"}>
                    <a href={`${base_url}download-sample-file`} target={"_blank"}>Download sample file</a>
                  </div>
                </div>
              </div>
            </div>
            <CommonSubmitButton disabled={false}/>
          </form>
        </CreateLayout>
   )
  }
}