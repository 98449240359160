import API from "../common/AxoisClient";
import { SportModel } from "../model";

export class SportService {

  static async get(id: number){
    const res = await API.get(`quiz/sports/${id}`);
    return res.data
  }


  static async store(data: SportModel) {
    const res = await API.post(`quiz/sports`, data);
    return res.data
  }

  static async update(data: SportModel, id: number){
    const res = await API.post(`quiz/sports/${id}`, {...data, "_method": "put"});
    return res.data
  }

  static async getList(){
    const res = await API.get(`quiz/sports/get-sports`);
    return res.data
  }
}