import * as React from 'react';
import {SportModel} from "../../model";
import {IState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {humanifyDate, listingStatus} from "../../helpers/UserHelper";


export class Sports extends React.Component<RouteComponentProps, IState> {
  records:React.RefObject<ShowRecords> = React.createRef();


  showContent = (data: SportModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("name", data.name),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
      this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: SportModel) => {
    return (
      <>
      <EditLink scope={'sports.update'} to={`${this.props.match.url}/edit/${data.id}`} />
      </>
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"sports"} icon={Icons.football} >
            <ExportButton records={this.records} fileName={"sportss"}/>
            &nbsp;
            <CreateLink to={`/quiz/sports/create`} scope={'sports.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} />}
            table={(
              <ShowRecords
                url={"/quiz/sports"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "quiz/sports/bulk-action", "module": 'sports'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
