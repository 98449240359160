import * as React from 'react';
import {PillarModel} from "../../model";
import {IState as CommonState} from "../../common/State";
import {ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import { ListForm, ListHeader, ListingLayout } from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import { humanifyDate } from "../../helpers/UserHelper";

interface IState extends CommonState{
  pillars: Array<PillarModel>;
   
}

export class Pillars extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();


  showContent = (data: PillarModel, index: number) => {
   
    let cols = [];
    cols.push(...[
        this.records.current.createdTd("name", data.name),
        this.records.current.createdTd("color_code", () => <div style={{height:10, width:10, backgroundColor: data.color_code}}/>),
        this.records.current.createdTd("description", <span dangerouslySetInnerHTML={{__html: data.description}} />),
        this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
        this.records.current.createdTd("action", () =>  this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: PillarModel) => {
    return (
      <EditLink scope={'pillars.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Pillars"} icon={Icons.bank} >
            <ExportButton records={this.records} fileName={"Pillars"}/>
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} />}
            table={(
              <ShowRecords
                url={"/pillars/pillars"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={false}
                showCheckbox={false}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
