import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {AnsFeedbackModel} from "../../model";
import {FeedbackForm} from "./form/FeedbackForm";

export class AnsFeedbackCreate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      feedback: {} as AnsFeedbackModel
    }
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'Add feedbacks for selected Sport, Age Group and Concepts'}>
          <FeedbackForm {...this.state}/>
        </CreateLayout>
    )
  }
}