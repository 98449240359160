import * as React from 'react';
import {Breadcrumb, LoaderType, ShowRecords, showNotification} from "../../common";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import API from "../../common/AxoisClient";
import {NotificationModel} from "../../model/Notification";
import {NotificationBreadcrumb} from "../../users/customers/Data";
import {SubmitButton,SwitchLoader} from "../../common";

export interface NotificationState {
  notificationList : Array<NotificationModel>;
  notificationName : Array<NotificationModel>;
  id: number;
}

export class Notification extends React.Component<RouteComponentProps,NotificationState> {
  state: NotificationState;
  records:React.RefObject<ShowRecords> = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      dataType: '',
      notificationList: [],
      notificationName: [],
    } as any;
  }

  handleCheckboxChange = (event : React.ChangeEvent<any>) => {
    const dataIndex = event.target.getAttribute('data-index');
    const isChecked:any = this.state.notificationList;
    if(event.target.checked===true) {
      isChecked[dataIndex].is_on = 1;
      this.setState({
        notificationList: isChecked
      });
    } else {
      isChecked[dataIndex].is_on = 0;
      this.setState({
        notificationList: isChecked
      });
    }
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
      API.post(`/users/users/notification`,this.state.notificationList).then((res) => {
        showNotification("success", Lang.notification_updated);
      })
  }

  componentDidMount() {
    API.get(`/users/users/get-notification-data/${this.state.id}`).then(res => {
     this.setState({
      notificationList: res.data.notificationData,
      notificationName: res.data.notificatioName
    });
    }).catch(()=> {});
  }

  render() {
    //const  isChecked  = this.state;
    return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
     <Breadcrumb data={NotificationBreadcrumb}/>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">{Lang.notification}</h3>
                </div>
              </div><br/>
              <form className="kt-form" onSubmit={this.handleSubmit} id={'update_notification'}>
                <div className="kt-portlet__body">
                  <div className={'row'}>
                    <div className={"col-md-6 col-lg-6"}>
                      <div className="form-group">
                        {this.state.notificationList.map((item: NotificationModel, index: number) => {
                         if((index>=3) && this.props.match.url.indexOf("translator")>0){
                           return (
                               <label key={item.id} className="kt-checkbox">
                                 <input type="checkbox" id={item.notification_type} value={1} name={item.notification_type} onChange={this.handleCheckboxChange} checked={item.is_on===1} data-index={index} /><label>{item.notification_type_title} </label>
                                 <span></span>
                               </label>
                           )
                         }
                          if(this.props.match.url.indexOf("customers")>0){
                            return (
                                <label key={item.id} className="kt-checkbox">
                                  <input type="checkbox" id={item.notification_type} value={1} name={item.notification_type} onChange={this.handleCheckboxChange} checked={item.is_on===1} data-index={index} /><label>{item.notification_type_title}</label>
                                  <span></span>
                                </label>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-lg-6">
                      </div>
                      <div className="col-lg-6 kt-align-right">
                      <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}