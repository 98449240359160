// @ts-ignore
import API from "../common/AxoisClient";

export class UserService {

  /**
   * get user's roles list
   */
  static async getRoles(){
    const res = await API.get("users/users/get-roles");
    return res.data
  }

  /**
   * get user's roles list
   */
  static async getAdminRoles(){
    const res = await API.get("users/admin-users/get-admin-roles");
    return res.data
  }

  /**
   * get user info
   * @param id
   */
  static async getUserInfo(id: number){
    const res  = await API.get(`/users/users/${id}`);
    return res.data;
  }

}