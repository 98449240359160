import * as React from 'react';
import {ActionLink, SubmitButton} from "../../common";
import Lang from "../../common/lang/Lang";


export default class LoginForm extends React.Component<any, any>{

  render(){
    const {form, handleSubmit, handleChange, handleForgot} = this.props;
    return (
      <div className="kt-login__signin">
        <div className="kt-login__head">
          <h3 className="kt-login__title">Sign In To Admin</h3>
        </div>
        <form className="kt-form" method="POST" action="" id="login_form" onSubmit={handleSubmit}>
          <div className="input-group">
            <input id="email" type="email" placeholder="Email" value={form.email}  onChange={handleChange} className="form-control" name="email" required />
          </div>
          <div className="input-group">
            <input id="password" type="password" placeholder="Password" value={form.password}  onChange={handleChange} className="form-control" name="password" required />
          </div>
          <div className="row kt-login__extra">
            <div className="col">
              <label className="kt-checkbox">
                <input className="form-check-input" type="checkbox" name="remember" value={form.remember} id="remember" onChange={handleChange}/>
                {'Remember Me'}
                <span/>
              </label>
            </div>

            <div className="col" style={{textAlign: 'right'}} onClick={handleForgot}>
              Forgot Password
            </div>

          </div>
          <div className="kt-login__actions">
            <SubmitButton className="btn btn-brand btn-elevate kt-login__btn-primary" label={Lang.sign_in}/>

          </div>
        </form>
      </div>
    )
  }
}
