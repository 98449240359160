import * as React from 'react';
import {IState} from "../../common/State";
import {actionButton, ActionLink, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {humanifyDate, listingStatus} from "../../helpers/UserHelper";
import { PopupModel } from '../../model';


export class Popups extends React.Component<RouteComponentProps, IState> {
  records:React.RefObject<ShowRecords> = React.createRef();


  showContent = (data: PopupModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{ this.records.current.checkbox(data.id) }</td>,
      this.records.current.createdTd("title", data.title),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
      this.records.current.createdTd("action", () => this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: PopupModel) => {
    return (
      <>
      <EditLink scope={'popups.update'} to={`${this.props.match.url}/edit/${data.id}`} title='Edit' />
      <ActionLink scope={'popups.index'} to={`${this.props.match.url}/info/${data.id}`} title='Info' >
        <i className={Icons.view} />
      </ActionLink>
      </>
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Popups"} icon={Icons.contract} >
            <ExportButton records={this.records} fileName={"popups"}/>
            &nbsp;
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} />}
            table={(
              <ShowRecords
                url={"/quiz/popups"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "quiz/popups/bulk-action", "module": 'popups'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
