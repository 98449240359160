import {RoleModel} from "./Role";
import {AgeGroupModel} from "./AgeGroup";

export interface UserModel {
  id?: number;
  value?: number;
  role_id?: number | string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  mobile_no: string;
  password?: string;
  profile_image?: string;
  status?: number;
  created_at?: string;
  deleted_at?: string;
  registration_from?: string;
  is_online?: number;
  old_c_id?: number;
  old_t_id?: number;
  last_login?: string;
  dob?: string;
  email_verified_at: string;
  phone_verified_at: string;

  role?: RoleModel;
  age_group?: AgeGroupModel;
}

export const AdminUserFormData = (data? : UserModel) => {
  return {
    role_id: data !== undefined ? data.role_id : '',
    first_name: data !== undefined ? data.first_name : '',
    last_name: data !== undefined ? data.last_name : '',
    email: data !== undefined ? data.email : '',
    username: data !== undefined ? data.username : '',
    password: '',
    cn_password: '',
    mobile_no: data !== undefined ? data.mobile_no : '',
  }
};

export const COACH_DIRECTOR = 2;
export const COACH = 3;
export const PLAYER = 4;