import React from "react";
import {RouteComponentProps} from "react-router";
import {getUserQuestionnaire} from "../../../services";
import {
  CompetenceModel,
  ConceptModel,
  PillarModel,
  QuestionnaireAnswerModel,
} from "../../../model";
import classNames from "classnames";
import {showNotification} from "../../../common";
import {humanifyDate} from "../../../helpers/UserHelper";
import Swal from "sweetalert2";
import {default as Lang} from "../../../common/lang/Lang";
import API from "../../../common/AxoisClient";

export const Questionnaire = (props: RouteComponentProps) => {
  const { match:{ params } }  = props;
  const {id}: any = params;

  const [questionnaire, setQuestionnaire] = React.useState([]);

  React.useEffect(() => {
    init();
  }, [])

  async function init() {
    const data  = await getUserQuestionnaire(id);
    setQuestionnaire(data);
  }


  function deleteAns(id) {
    Swal.fire({
      title: Lang.is_sure,
      text: 'Are you sure that you want to delete this answer? ',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        API.post('/users/customers/answer/delete', {id}).then((response : any) => {
          if(response.data === true){
            showNotification('success', 'Answer has been successfully deleted.')
            init();
          } else {
            showNotification('error', 'Oops... Something went wrong. Please try again.')
          }
        }).catch(() => {});
      }
    })
  }

  return (
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              User Questionnaires
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="accordion  accordion-toggle-arrow" id="accordionExample4">
            <Questionnaires questionnaire={questionnaire} deleteAns={deleteAns}/>
          </div>
        </div>
      </div>
    </div>
  )
}

const Questionnaires = ({questionnaire, deleteAns}) => {
  return questionnaire.map((item: PillarModel, index: number) => {
    return (
      <div className="card" key={index}>
        <div className="card-header" id={`pillar_heading_${item.id}`}>
          <div className={ classNames("card-title", { "collapsed" : index > 0 }) } data-toggle="collapse" data-target={`#pillar_${item.id}`}
               aria-expanded={index === 0 ? "true": "false"} aria-controls={`pillar_${item.id}`}>
            {item.name}
          </div>
        </div>
        <div id={`pillar_${item.id}`} className={ classNames("collapse", { "show" : index === 0 }) } aria-labelledby="headingOne"
             data-parent="#accordionExample4">
          <div className="card-body">
            <Competences competences={item.competence} color={item.color_code} deleteAns={deleteAns}/>
          </div>
        </div>
      </div>
    )
  });
}

const Competences = ({competences, color, deleteAns}) => {
  return competences.map((com: CompetenceModel) => {
    return (
      <div style={{marginTop: 10}} key={com.id}>
        <h5>{com.name}</h5>
        <Concepts {...com} color={color} deleteAns={deleteAns} />
      </div>
    )
  });
}


const Concepts = ({concepts, color, deleteAns}) => {
  return concepts.map((concept: ConceptModel, index: number) => {
    return (
      <div key={index} style={{marginTop: 10}}>
        <h6>{concept.name}</h6>
        <Questions {...concept} color={color} deleteAns={deleteAns}/>
      </div>
    )
  })
}


const Questions = ({question, color, deleteAns}) => {
  console.log("question.answers", question.answers)
  return question.answers.map((ans: QuestionnaireAnswerModel, index: number) => {
    
    const width = (ans.answer / 10 )*100;
    return (
      <div key={index}>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '85%', marginBottom: 5}}>
          <div>Submitted on { humanifyDate(ans.test.created_at)}</div>
          <div>Session From { humanifyDate(ans.test.session.start_date)} to { humanifyDate(ans.test.session.end_date)}</div>
        </div>
        <div className="progress progress--sm" style={{width:"85%", float:"left"}}>
          <div className="progress-bar" role="progressbar"
               style={{width: `${width}%`, backgroundColor: color}}
               aria-valuenow={ans.answer}
               aria-valuemin={0}
               aria-valuemax={10}/>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div>
            {ans.answer}
          </div>
          <div>
            <button className={'btn-danger'} onClick={() => deleteAns(ans.id)} ><i className={'fa fa-trash'}></i></button>
          </div>
        </div>
      </div>
    )
  })
}
