import React from 'react';
import {default as Lang} from "../../common/lang/Lang";
import {GeneratePermitLink, Icons, showConfirmBox } from "../../common";
import { FROM_WEB_URL } from "../../common/AxoisClient";
import {sendMail, sudoLogin} from "../../services";
import { Auth } from '../../Auth/Auth';

export function CustomerActions(props) {

  async function submitLoginForm(userId) {
    await showConfirmBox("Are you sure that you want to login to this user?", async () => {
      const res = await sudoLogin(userId);

      var form = document.createElement("form");
      form.setAttribute("method", 'get');
      form.setAttribute("action", `${FROM_WEB_URL}sudo-login`);
      form.setAttribute("target", '_blank');

      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", '_token');
      hiddenField.setAttribute("value", res);

      var hiddenUserField = document.createElement("input");
      hiddenUserField.setAttribute("type", "hidden");
      hiddenUserField.setAttribute("name", '_user');
      hiddenUserField.setAttribute("value", `${Auth.userInfo.id}`);
      
      form.appendChild(hiddenField);
      form.appendChild(hiddenUserField);
      document.body.appendChild(form);
      form.submit();
      
    })
  }

  return (
        <span style={{overflow: 'visible', position: 'relative', width: '80'}}>
          <div className="dropdown">
            <a data-toggle="dropdown" className="btn btn-sm btn-clean btn-icon btn-icon-md" aria-expanded="false">
              <i className="flaticon-more-1"/>
            </a>
            <div className="dropdown-menu dropdown-menu-right"  x-placement="bottom-end">
              <ul className="kt-nav">
               
                <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'users.resend-email'} to={'#'} onClick={() => sendMail(props.customer_data)}>
                      <i className={`${Icons.email}`} />&nbsp;&nbsp;<span className="kt-nav__link-text">Send confirmation mail</span>
                  </GeneratePermitLink>
                </li>
                <li className="kt-nav__item">
                  <GeneratePermitLink className="kt-nav__link" scope={'users.impersonate'} to={'#'} onClick={() => submitLoginForm(props.customer_data)}>
                      <i className={`${Icons.password}`} />&nbsp;&nbsp;<span className="kt-nav__link-text">Sudo Login</span>
                  </GeneratePermitLink>
                </li>
                  
              </ul>
            </div>
          </div>
        </span>

  )
}