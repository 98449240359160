import {ClubModel, ClubSettingsModel, ConceptModel, ThemeButton} from "../../model";

export const urls = {
  home: "/clubs/",
  list: "/clubs/clubs",
}

export const ListBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];
export const ImportBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Import",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export const ClubSettingBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Clubs",
    url: urls.list
  },
  {
    label: "Settings",
    url: '#'
  }
];


export const PRIMARY_BUTTON = {
  bg_color: "#111111",
  hover_color: "#274702",
  text_color: "#ffffff",
  active_color: "#111111",
} as ThemeButton;

export const SECONDARY_BUTTON = {
  bg_color: "#643c8c",
  hover_color: "#1f132c",
  text_color: "#ffffff",
  active_color: "#ab97bf",
} as ThemeButton;


export interface IState {
  id: number,
  club?: ClubModel,
}

type InitFn = () => Promise<void>;


export interface CSState {
  id: number;
  club: ClubModel;
  clubSetting?: ClubSettingsModel;
  handleSuccess?: InitFn
}

export interface ImportIState {
  id: number,
  file: string,
}