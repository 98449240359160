import * as React from 'react';
import {ImportBreadcrumb, ImportIState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {base_url, CommonSubmitButton, showNotification} from "../../common";
import API from "../../common/AxoisClient";
import {state_to_form_data} from "../../helpers/UserHelper";
import {get} from "lodash"

export class ClubImport extends React.Component<RouteComponentProps, ImportIState > {
  state: ImportIState;

  handleSubmit = event => {
    event.preventDefault();
    API.post("clubs/clubs/import", state_to_form_data(this.state)).then(res => {
      if(res.data.status === false) {
        showNotification("danger", res.data.errors);
      }
      showNotification("success", res.data.message);
      
    }).catch((error) => {
      console.log(error);
    })
  }


  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0]  : target.value;
    this.setState({
      file:  value
    })
  }

  render() {
    return (
        <CreateLayout breadcrumb={ImportBreadcrumb} title={'Import Teams'}>
          <form className="kt-form" id={'customer_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body">
              <div className={'row'}>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label>File  <span className={'required'}>*</span>
                    </label>
                    <div/>
                    <div className="custom-file">
                      <input onChange={this.handleChange} className="custom-file-input"  type={"file"} name={"import_file"} required={true} />
                      <label className="custom-file-label" htmlFor="customFile">{get(this.state, "file.name", "")}</label>
                    </div>
                  </div>
                </div>

                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group"/>
                  <div className={"form-field"}>
                    <a href={`${base_url}download-sample-file-club`} target={"_blank"}>Download sample file</a>
                  </div>
                </div>
                <div className={"col-md-6 col-lg-6"}>
                  <div className="form-group">
                    <label className={"alert-warning"}>Note: 1. Age group should be U-9, U-12, U-16 or 17+</label>
                    <label className={"alert-warning"}>Note: 2. User type should be  player or coach</label>
                  </div>
                </div>
              </div>
            </div>
            <CommonSubmitButton disabled={false}/>
          </form>
        </CreateLayout>
    )
  }
}