import API from "../common/AxoisClient";
import { AnsFeedbackModel } from "../model";

export class AnsFeedbackService {

  static async get(id: number){
    const res = await API.get(`quiz/ans-feedback/${id}`);
    return res.data
  }


  static async store(data: AnsFeedbackModel) {
    const res = await API.post(`quiz/ans-feedback`, data);
    return res.data
  }

  static async update(data: AnsFeedbackModel, id: number){
    const res = await API.post(`quiz/ans-feedback/${id}`, {...data, "_method": "put"});
    return res.data
  }
  
}