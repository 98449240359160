import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { SportModel} from "../../model";
import {SportForm} from "./form/SportForm";
import {SportService} from "../../services";

export class SportEdit extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      sport: {} as SportModel
    }
  }

  init = async () => {
    const sport: SportModel = await SportService.get(this.state.id)
    this.setState({
      sport
    })
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'Edit'}>
          <SportForm {...this.state}/>
        </CreateLayout>
    )
  }
}