import React, { createContext, useState } from "react";

interface ModalContextValue {
  showModal: (data: any) => void;
  hideModal: () => void;
  data: any;
  show: boolean;
}

export const ModalContext = createContext<ModalContextValue>({
  showModal: () => {},
  hideModal: () => {},
  data: null,
  show: false,
});

//export const useModalContext = () => useContext(ModalContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const showModal = (item: any) => {
    console.log("HERE IT IS");
    setShow(true);
    setData(item);
  };

  const hideModal = () => {
    setShow(false);
    setData(null);
  };

  const value = {
    showModal,
    hideModal,
    data,
    show
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
