import React from 'react';
import {Modal} from "react-bootstrap";
import {PopupInfoModel} from "../../../model";
import { SwitchLoader } from "./../../../common/AxoisClient"
import { CommonSubmitButton, LoaderType, RenderForm, showNotification } from '../../../common';
import _, { isEmpty } from 'lodash';
import { PopupService } from '../../../services';

export const PopupInfoModal = (props) => {

  const [form, setForm] = React.useState({} as PopupInfoModel);
  const {popup, onHide, intro, info} = props;
 
  React.useEffect(() => {
    if(info !== undefined && !isEmpty(info)) {
      setForm(info);
    }
  }, [info]);

  function hideModel() {
    setForm({} as PopupInfoModel)
    onHide();
  }

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    if(name === 'file') {
      setForm({...form, [name]: target.files[0]});
    } else {
      setForm({...form, [name]: value});
    }
  }

  const handleEditorChange = (message : any, editor : any) => {
    setForm({...form, description: message})
  }

  const FormInputs = [
    {
      "name" : "title",
      "value" : _.get(form, "title", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter title",
      "label": "Title",
      "parent_class": "col-md-12 col-lg-12",
    },
    {
      "name" : "description",
      "value" : _.get(form, "description", ""),
      "onEditorChange": handleEditorChange,
      "required": true,
      "placeholder": "Enter description",
      "label": "Description",
      "type": 'editor',
      "parent_class": "col-md-12 col-lg-12"
    },
    {
      "name" : "file",
      "required": true,
      "onChange": handleChange,
      "type": "file",
      "label": "Image",
      "file_path":  _.get(form, "image", ""),
      "parent_class": "col-md-12 col-lg-12"
    }
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(info !== undefined && !isEmpty(info)){
      PopupService.updatePopupInfo(popup.id, info.id, form).then((message) => {
        showNotification("success", message);
        hideModel();
      }).catch(() => {});
    } else {
      PopupService.storePopupInfo(intro.id, form).then((message) => {
        showNotification("success", message);
        hideModel();
      }).catch(() => {});
    }
  }

  return(
    <Modal
      {...props}
      onHide={hideModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Popup Content for <b><i>{popup.title}</i></b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={false}/>
        </form>
      </Modal.Body>
    </Modal>
  )
};
