import {AgeGroupModel, ClubModel, ConceptModel, TeamModel, UserModel} from "../../model";

export const urls = {
  home: "/clubs/",
  list: "/clubs/teams",
}

export const ListBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Teams",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Teams",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const ImportBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Teams",
    url: urls.list
  },
  {
    label: "Import",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Clubs",
    url: urls.home
  },
  {
    label: "Teams",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];

export interface IState {
  id: number,
  team?: TeamModel,
}
export interface ImportIState {
  id: number,
  file: string,
}

export interface TeamFormData {
  clubs: Array<ClubModel>,
  coaches: Array<UserModel>,
  age_groups: Array<AgeGroupModel>
}