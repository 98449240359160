import React from "react";
import _ from "lodash";
import {CommonSubmitButton, isEmpty, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {TeamModel, UserModel} from "../../../model";
import {IState, TeamFormData} from "../Data";
import {ClubService} from "../../../services";
import {AddPlayer} from "../modal/AddPlayer";
import {get} from "lodash"


export const TeamForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as TeamModel);
  const [data, setData] = React.useState({} as TeamFormData);
  const [playerPopupOpen, setPlayerPopupOpen] = React.useState(false);
  const [roleId, setRoleId] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]:  value})
  }

  const handlePlayers = field => (inputValue) => {
    setForm({...form, [field]:  inputValue})
  }

  React.useEffect(() => {
    const {team} = props;
    if(team){
      team["team_members_field"] =  defaultTeamMembers(team.team_members) ;
      team["team_coaches_field"] =  defaultTeamMembers(team.team_coaches) ;
      setForm(team)
    }
  },[props.team])

  React.useEffect(() => {
    async function init() {
      const dataForTeam:TeamFormData = await ClubService.dataForTeam();
      if(props.team && props.team.coach){
        dataForTeam["coaches"] =  [...dataForTeam["coaches"], props.team.coach];
      }
      setData(dataForTeam)
    }
    init()
  },[props.team])

  const loadPlayer = async (inputValue, callback) => {
    const playersData = await ClubService.playersList(inputValue, _.get(form, "age_group_id", 0) );
    callback(playersData);
  }

  const loadCoaches = (data) => (inputValue, callback) => {
    callback(data);
  }

  function defaultTeamMembers(team_members) {
    let teamMembers = [];
    const oldMembers = team_members;
    if(oldMembers){
      // @ts-ignore
      oldMembers.map(item => teamMembers.push({value: item.user_id,user_id: item.user_id, label: item.user.name}))
    }
    return teamMembers;
  }

  const FormInputs = [
    {
      "name" : "name",
      "value" : _.get(form, "name", ""),
      "onChange": handleChange,
      "required": true,
      "placeholder": "Enter team name",
      "label": "Team name",
    },
    {
      "name" : "club_id",
      "value" : _.get(form, "club_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Club",
      "label": "Club",
      "options" : _.get(data, "clubs", [])
    },
    
    {
      "name" : "age_group_id",
      "value" : _.get(form, "age_group_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Age Group",
      "label": "Age Group",
      "options" : _.get(data, "age_groups", [])
    },
    {
      "name" : "sport_id",
      "value" : _.get(form, "sport_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Select Sport",
      "label": "Sport",
      "options" : _.get(data, "sports", [])
    },

    {
      "name" : "user_id",
      "value" : _.get(form, "team_coaches_field", []),
      "onChange":  handlePlayers('team_coaches_field'),
      "isMulti": true,
      "type": "select2",
      "placeholder": "Select Coach",
      "label": "Team Coach",
      // "options" : _.get(data, "coaches", []),
      "loadOptions" : loadCoaches(_.get(data, "coaches", [])),
      "extra" : <button style={{marginTop: 10}} type={"button"} className={"btn btn-primary"} onClick={() => {setRoleId(3); setPlayerPopupOpen(!playerPopupOpen)}} >Add new coach</button>
    },
    {
      "name" : "team_members_field",
      "value" :  _.get(form, "team_members_field", []),
      "onChange": handlePlayers('team_members_field'),
      //"required": true,
      "isMulti": true,
      "type": "select2",
      "placeholder": "Select Players",
      "label": "Players",
      "loadOptions" : loadPlayer,
      "isDisabled": _.get(form, "age_group_id", 0) <= 0,
      "extra" : <button style={{marginTop: 10}} type={"button"} className={"btn btn-primary"} onClick={() => {setRoleId(4); setPlayerPopupOpen(!playerPopupOpen)}} >Add new player</button>
    },

  ];

  function isDisable() {
    return FormInputs.reduce((acc, val) => acc ? acc : isEmpty(val.value) && val.required, false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id){
      ClubService.updateTeam(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/teams`)
      }).catch(() => {});
    } else {
      ClubService.storeTeam(form).then((message) => {
        showNotification("success", message);
        history.push(`/clubs/teams`)
      }).catch(() => {});
    }
  }

  const newPlayer = (user: UserModel) => {
    if(roleId === 4){
      setForm({...form, team_members_field:  [...get(form, "team_members_field", []), user]});
    } else {
      setForm({...form, team_coaches_field:  [...get(form, "team_coaches_field", []), user]});
      setData({...data, "coaches": [...data.coaches, user]})
    }
  }

    return(
      <React.Fragment>
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton disabled={isDisable()}/>
        </form>
        
        <AddPlayer
          show={playerPopupOpen}
          role_id={roleId}
          onHide={() => setPlayerPopupOpen(!playerPopupOpen)}
          onPlayerAdd={newPlayer}
          ageGroups= {data.age_groups}
        />
      </React.Fragment>
      )
}