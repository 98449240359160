import React from 'react';

import _ from "lodash";
const moment = require("moment");
declare let window:any;


export function list_key(id: number) {
  let k = _.add(moment(), id) + _.random(999999);
  return k.toString();
}