import React from 'react';
import {Modal} from "react-bootstrap";
import {PLAYER, PopupIntroModel} from "../../../model";
import { SwitchLoader } from "./../../../common/AxoisClient"
import { CommonSubmitButton, LoaderType, RenderForm, showNotification } from '../../../common';
import _, { isEmpty } from 'lodash';
import { PopupService } from '../../../services';

export const IntroPopupModal = (props) => {

  const [form, setForm] = React.useState({} as PopupIntroModel);
  const {intro, onHide, show, info, popup} = props;
 
  React.useEffect(() => {
    if(info !== undefined && !isEmpty(info)) {
      setForm(info);
    }
  }, [info]);

  function hideModel() {
    setForm({} as PopupIntroModel)
    onHide();
  }

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]: value});
  }

  const FormInputs = [
    {
      "name" : "sport_id",
      "value" : _.get(form, "sport_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Choose sport",
      "label": "Sport",
      "options": intro.sports,
    },

    {
      "name" : "role_id",
      "value" : _.get(form, "role_id", ""),
      "onChange": handleChange,
      "required": true,
      "type": "select",
      "placeholder": "Choose role",
      "label": "Role",
      "options": intro.roles,
    },
    {
      "name" : "age_group_id",
      "value" : _.get(form, "age_group_id", ""),
      "onChange": handleChange,
      //"required": true,
      "type": "select",
      "placeholder": "Choose age group",
      "label": "Age group",
      "options": intro.age_groups,
      "show": form.role_id == PLAYER
    },
    
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    PopupService.storePopupIntro(popup.id, form).then((message) => {
      showNotification("success", message);
      hideModel();
    }).catch(() => {});
  }
  const isDisable = () => FormInputs.reduce((acc, val) => acc ? acc : isEmpty(val.value) && val.required, false);
  return(
    <Modal
      {...props}
      onHide={hideModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Popup Intro Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
          <div className="kt-portlet__body">
            <div className={'row'}>
              <RenderForm items={FormInputs}/>
            </div>
          </div>
          <CommonSubmitButton  disabled={isDisable()}/>
        </form>
      </Modal.Body>
    </Modal>
  )
};
