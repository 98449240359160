import React from 'react';
declare var window: any;
export function NotFound() {
    return(
        <div className="kt-container page404container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{backgroundImage: `url(${window.PUBLIC_URL}/admin/assets/media/error/bg6.jpg)`}}>
            <div style={{height: "68vh"}}>
                <div className="kt-error_container page404">
                    <img src={`${window.PUBLIC_URL}/admin/assets/media/error/bg6.jpg`} />
                    <p className="kt-error_description">
                        Oops...<br/>
                        Page not found.
                    </p>
                </div>
            </div>
        </div>
    );
}