import * as React from 'react';
import {QuestionnaireTypeModel} from "../../model";
import {IState} from "../../common/State";
import {actionButton, CreateLink, ExportButton} from "../../common";
import {RouteComponentProps} from "react-router";
import {ListBreadcrumb} from "./Data";
import { EditLink, ShowRecords, Icons} from "../../common";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";
import Filters from "./Filters";
import {humanifyDate, listingStatus} from "../../helpers/UserHelper";


export class QuesTypes extends React.Component<RouteComponentProps, IState> {
  records:React.RefObject<ShowRecords> = React.createRef();


  showContent = (data: QuestionnaireTypeModel, index: number) => {
    
    let cols = [];
    cols.push(...[
      <td key={index}>{ data.id !== 1 ? this.records.current.checkbox(data.id): ''}</td>,
      this.records.current.createdTd("title", data.title),
      this.records.current.createdTd("description", <p dangerouslySetInnerHTML={{__html: data.description}}></p>),
      this.records.current.createdTd("status", () => listingStatus(data.status)),
      this.records.current.createdTd("created_at", humanifyDate(data.created_at)),
      this.records.current.createdTd("action", () => this.rowActionButtons(data))
    ]);
    cols = _.compact(cols);
    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }


  rowActionButtons = (data: QuestionnaireTypeModel) => {
    return (
      <>
      <EditLink scope={'ques-types.update'} to={`${this.props.match.url}/edit/${data.id}`} />
      </>
    )
  }

  render() {
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={"Questionnaire Type"} icon={Icons.football} >
            <ExportButton records={this.records} fileName={"ques-types"}/>
            &nbsp;
            <CreateLink to={`/quiz/ques-types/create`} scope={'ques-types.store'} />
          </ListHeader>

          <ListForm
            form={<Filters getResults={this.filterRecords} />}
            table={(
              <ShowRecords
                url={"/quiz/ques-types"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "quiz/ques-types/bulk-action", "module": 'ques-types'})}
                rowActions={this.rowActionButtons}
               />
            )}
          />
        </ListingLayout>
    )
  }
}
