import * as React from 'react';
import {ClubSettingBreadcrumb, CSState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {ClubSettingForm} from "./form/ClubSettingForm"
import {ClubModel, ClubSettingsModel} from "../../model";
import {ClubService} from "../../services";

export class ClubSettings extends React.Component<RouteComponentProps, CSState > {
  state: CSState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      club: {} as ClubModel,
      clubSetting: {} as ClubSettingsModel
    }
  }

  

  clubSettingInfo = async () => {
    const clubSetting: ClubSettingsModel = await ClubService.getClubSettings(this.state.id);
    this.setState({ clubSetting });
  }

  componentDidMount() {
    this.clubSettingInfo();
  }

  onSettingsAdded = (clubSetting: ClubSettingsModel) => {
    this.setState({ clubSetting });
  }

  render() {
    return (
        <SettingFormContainer params={ this.props.match.params }>
          <ClubSettingForm {...this.state} handleSuccess={this.clubSettingInfo}/>
        </SettingFormContainer>
    )
  }
}

function SettingFormContainer(props: any) {
  const [club, setClub] = React.useState({} as ClubModel);
  React.useEffect(() => {
    loadClubInfo();
  }, []);

  const loadClubInfo = async () => {
    const club: ClubModel = await ClubService.getClubInfo(props.params.id);
    setClub(club);
  }
  return (
    <CreateLayout breadcrumb={ClubSettingBreadcrumb} title={`Club '${club.name}' Settings`}>
      {props.children}
    </CreateLayout>
  )
}