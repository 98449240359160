import API, {base_url} from "../common/AxoisClient";
import {get} from 'lodash';
import {CONST} from "../common";
const moment = require("moment");

interface UserInfo {
  name: string;
  first_name: string;
  last_name: string;
  id: number;
  email: string;
  mobile_no?: string;
  country_code?: string;
  role_id: number;
  scopes: Array<string>
  token: string;
}

interface AuthSchema {
  isAuthenticated: boolean;
  userInfo: UserInfo|null;
  token: string;
  dateFormat?: string;
  
  authenticate: (data: any) => void;
  signout: () => void;
  reAuthenticate: ($token: string) => any;
  systemlogout: ($token: string) => any;
  setToken: ($token: string) => any;
  authoriseLogin: (data: string, props: any) => any;
}
export const Auth : AuthSchema = {
  isAuthenticated: false,
  userInfo: null,
  token: '',
  dateFormat: 'DD-MM-yyyy',
  
  authenticate( data: any) {
    this.isAuthenticated = true;
   // localStorage.setItem('userInfo', JSON.stringify(data));
    this.userInfo = data;
    this.token = data._token;
    this.dateFormat = get(data, 'date_format.back_end', CONST.date_format);
  },
  signout() {
    this.isAuthenticated = false;
     this.userInfo = null;
    this.token = null;
  },

  async reAuthenticate(token: string) {
    this.token = token;
    const res = await API.get(`${base_url}re-authenticate`);
    Auth.authenticate(res.data);
    this.token = token;
    return res.data;
  },

  async systemlogout(token: string) {
    this.isAuthenticated = false;
    const res = await API.post(`${base_url}api/logout`);
    this.token = null;
    this.userInfo = null;
    return res.data;
  },
  setToken(token: string) {
    this.token = token;
  },

  authoriseLogin(data: any, props: any){
    const {cookies, remember} = props;
    Auth.authenticate(data);
    console.log(data);
    let expire_time = {};
    if(remember){
      expire_time['expires'] = new Date(moment(new Date()).add(30, 'days'));
    }
    if(data.new_device === true){
      cookies.set('my_device', data.device_token, { path: '/', HttpOnly:true, sameSite:true, expires: new Date(moment(new Date()).add(365, 'days')) });
    }
    cookies.set('remember', remember, {...{ path: '/', HttpOnly:true, sameSite:true }, ...expire_time});
    cookies.set('remember_token', data._token, {...{ path: '/', HttpOnly:true, sameSite:true }, ...expire_time});
    window.location.reload();
  }
};
