import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {ClubForm} from "./form/ClubForm"
import {ClubModel} from "../../model";
import {ClubService} from "../../services/";

export class ClubUpdate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      club: {} as ClubModel
    }
  }

  init = async () => {
    const pillarData: ClubModel = await ClubService.getClubInfo(this.state.id);
    this.setState({
      club: pillarData
    })
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'Edit'}>
          <ClubForm {...this.state}/>
        </CreateLayout>
    )
  }
}