import * as React from 'react';
import { CopyBreadcrumb, IState } from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {QuestionnaireModel} from "../../model";
import {QuestionnaireForm} from "./form/QuestionnaireForm";
import {QuestionnaireService} from "../../services";

export class QuestionnaireCopy extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      questionnaire: {} as QuestionnaireModel
    }
  }

  init = async () => {
    const questionnaireData: QuestionnaireModel = await QuestionnaireService.getQuestionnaireInfo(this.state.id)
    this.setState({
      questionnaire: questionnaireData
    })
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
        <CreateLayout breadcrumb={CopyBreadcrumb} title={'Copy'}>
          <QuestionnaireForm copy={true} {...this.state}/>
        </CreateLayout>
    )
  }
}