import {PopupModel} from "../../model";

export const urls = {
  home: "/quiz/",
  list: "/quiz/popups",
}

export const ListBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Popups",
    url: "#"
  }
];


export const CreateBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Popups",
    url: urls.list
  },
  {
    label: "Create",
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Popups",
    url: urls.list
  },
  {
    label: "Edit",
    url: '#'
  }
];


export const InfoBreadcrumb = [
  {
    label: "Quiz",
    url: urls.home
  },
  {
    label: "Popups",
    url: urls.list
  },
  {
    label: "Info",
    url: '#'
  }
];


export interface IState {
  id: number;
  form: PopupModel;
}