import API from "../common/AxoisClient";
import { state_to_form_data } from "../helpers/UserHelper";
import { PopupInfoModel, PopupIntroModel, PopupModel } from "../model";

export class PopupService {

  static async get(id: number){
    const res = await API.get(`quiz/popups/${id}`);
    return res.data
  }


  static async store(data: PopupModel) {
    const res = await API.post(`quiz/popups`, data);
    return res.data
  }

  static async update(data: PopupModel, id: number){
    const res = await API.post(`quiz/popups/${id}`, {...data, "_method": "put"});
    return res.data
  }

  static async getPopupInfo(id: number){
    const res = await API.get(`quiz/popups/${id}/info`);
    return res.data
  }

  static async storePopupInfo(id: number, data: PopupInfoModel) {
    const res = await API.post(`quiz/popups/${id}/info`, state_to_form_data(data));
    return res.data
  }

  static async updatePopupInfo(id: number, infoId: number, data: PopupInfoModel) {
    const res = await API.post(`quiz/popups/${id}/info/${infoId}`, state_to_form_data(data));
    return res.data
  }

  static async getPopupIntroInfo(){
    const res = await API.get(`quiz/popups/intro`);
    return res.data
  }

  static async storePopupIntro(id: number, data: PopupIntroModel) {
    const res = await API.post(`quiz/popups/${id}/intro`, data);
    return res.data
  }

  static async deletePopupInfo(id: number) {
    const res = await API.post(`quiz/popups/info/delete/${id}`,);
    return res.data
  }
}