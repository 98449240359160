import {AxiosError} from "axios";
import Lang from "./lang/Lang";
import {Auth} from "../Auth/Auth";
import {showNotification} from "./Common";
import _ from "lodash";

declare var window: any;

export const HandleServerError = (error : AxiosError) => {
  if(error.response !== undefined){
    switch (error.response.status) {
      case 401:
        Error401();
        break;

      case 403:
        Error403();
        break;

      case 404:
        //window.location.href = '/404'
        break;

      case 419:
        Error419();
        break;

      case 422:
        Error422(error);
        break;

      default:
        showNotification('danger', Lang.error_response);
    }
  } else {
    console.error(error)
    //showNotification('danger', Lang.error_response);
  }


};

export const Error401 = () => {
  showNotification('danger', 'Your session has been expired.');
  Auth.signout();
  window.location.href = '/';
};

export const Error403 = () => {
  showNotification('danger', 'You have not required permissions for this action.');
};

export const Error404 = () => {
  showNotification('danger', 'Page not found.');
};

export const Error419 = () => {
  showNotification('danger', 'Invalid request');
};

export const Error422 = (error: AxiosError) => {
  let $errors = error.response.data ;
  if(_.isString($errors)){
    showNotification('danger', $errors);
  } else{
    $.each( $errors.errors, function( key, value ) {
      $.each( value, function( key2, error ) {
        showNotification('danger', error);
      });
    });
  }

};


export const Error422Custom = ($errors : any) => {
  $.each( $errors.errors, function( key:any, value ) {
    $.each( value, function( key2:number, error:string ) {
      error = error.replace(/document.\d+/g, 'document');
      showNotification('danger', error);
    });
  });
};

export const HandleCatch = (e) => {
  console.log(e)
}