import * as React from 'react';
import {Form, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import API from './../../common/AxoisClient';
import {RouteComponentProps} from "react-router";
import {showNotification, SwitchLoader, LoaderType} from '../../common';
import {UserService} from "../../services/UserService";
import {UserForm} from "./form/UserForm";


export class CustomerEdit extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      form: {} as Form,
      id: props.match.params.id,
      roles: []
    }
  }

  componentDidMount() {
    const ___that = this;
    async function init() {
      const userInfo = await UserService.getUserInfo(___that.state.id);
      const roles = await UserService.getRoles();
      
      ___that.setState({
        form: userInfo,
        roles: roles
      });
    }
    init();
  }

 

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }
  

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    if($("#customer_form").valid()) {
        API.put(`users/customers/${this.state.id}`, {}).then((res) => {
          showNotification("success", res.data.message);
        });
    }
  }

  render() {
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-md-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {Lang.edit_customer}
                    </h3>
                  </div>
                </div>
                <br/>
                <UserForm {...this.state} update={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}