import * as React from 'react';
import {CopyBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import { ResourceModel} from "../../model";
import {ResourceForm} from "./form/ResourceForm";
import {ResourceService } from "../../services";
import { pick } from 'lodash';

export class ResourceCopy extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      resource: {} as ResourceModel
    }
  }

  init = async () => {
    const resource: ResourceModel = await ResourceService.get(this.state.id)
    this.setState({
      resource: pick(resource, ['role_id', 'age_group_id', 'sport_id', 'concept_id', 'title', 'tags']) as any
    })
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
        <CreateLayout breadcrumb={CopyBreadcrumb} title={'Copy'}>
          <ResourceForm copy={true} {...this.state}/>
        </CreateLayout>
    )
  }
}