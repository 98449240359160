import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {CreateLayout} from "../../layout/CreateLayout";
import {TeamForm} from "./form/TeamForm"
import {TeamModel} from "../../model";
import {ClubService} from "../../services/";

export class TeamUpdate extends React.Component<RouteComponentProps, IState > {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      id: props.match.params.id,
      team: {} as TeamModel
    }
  }

  init = async () => {
    const teamData: TeamModel = await ClubService.getTeamInfo(this.state.id);
    this.setState({
      team: teamData
    })
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
        <CreateLayout breadcrumb={EditBreadcrumb} title={'Edit'}>
          <TeamForm {...this.state}/>
        </CreateLayout>
    )
  }
}