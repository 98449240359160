import React, { Component } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';

export class SummerNote extends Component<any, any> {

  onChange = (content) => this.props.onEditorChange(content);

  onImageUpload(images, insertImage) {
    /* FileList does not support ordinary array methods */
    for (let i = 0; i < images.length; i++) {
      /* Stores as bas64enc string in the text.
       * Should potentially be stored separately and include just the url
       */
      const reader = new FileReader();
      reader.onloadend = () => { insertImage(reader.result); };
      reader.readAsDataURL(images[i]);
    }
  };
  
  render() {
    return (
      <ReactSummernote
        value={this.props.value}
        children={<div dangerouslySetInnerHTML={{ __html: this.props.value }}/>}
        options={{
          height: 200,
          codemirror: {
            mode: 'text/html',
          }
        }}
        onChange={this.onChange}
        onImageUpload={this.onImageUpload}
      />
    );
  }
}
