import React from "react";
import { getFieldValue } from "../../../helpers/UserHelper";

export const ThemeButtonForm = ({ ColorInputs }) => ColorInputs.map((item: any, index: number) => <ButtonsContainer {...item} key={index} > <ShowColorInputs {...item} /> </ButtonsContainer>);

function ButtonsContainer({children, button, group, fields}) {
	
	const [bgColour, setBgColour] = React.useState();
	const bgC = getFieldValue(fields, `${group}.bg_color`);
	const txC = getFieldValue(fields, `${group}.text_color`);
	const hoC = getFieldValue(fields, `${group}.hover_color`);
	const acC = getFieldValue(fields, `${group}.active_color`);

	React.useEffect(() => {
		setBgColour(bgC);
	}, [bgC]);

	const style = {
		tryButton: {
			backgroundColor: bgColour,
			color: txC,
			marginTop: 20
		}
	}
  return (
    <div className="row">
      <div className={"col-md-6 col-lg-6"}>
        <h5>{button}</h5>
        <div className="row">
          {children}
        </div>
      </div>
      <div className={"col-md-6 col-lg-6"}>
				<h5>Button on website</h5>
			<button type="button" 
				className="btn "
				style={style.tryButton}
				onMouseEnter={() => setBgColour(hoC)}
				onMouseLeave={() => setBgColour(bgC)}
				onMouseDown={() => setBgColour(acC)}
				>Try Me</button>
		
      </div>
    </div>
  );
}

const ShowColorInputs = ({fields}) => fields.map((item: any, index: number) => <ColorInputs {...item} key={index} />);

function ColorInputs(item) {	
	return (
		<div className={"col"}>
			<div className="form-group">
				<label>
					{item.label}
					<span className={"required"}>*</span>
				</label>
				<input className="form-control" {...item} />
			</div>
		</div>
	);
}