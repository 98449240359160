import React from 'react';
import _ from 'lodash';
import { default as Lang } from "../common/lang/Lang";
import moment from "moment";
import {Auth} from "../Auth/Auth";



export function state_to_form_data(state: any) {
  let formData = new FormData();
  _.forEach(state, (value, key) => {
    if((_.isObject(value) || _.isArray(value)) && !File.prototype.isPrototypeOf(value)){
      formData.append(key, JSON.stringify(value));
    }else {
      formData.append(key, value);
    }
  });
  return formData;
}

export function listingStatus(status) {
  if(status === 1){
    return <span className={'badge badge-primary'}>{Lang.active}</span>
  }
  if(status === 2){
    return <span className={'badge badge-warning'}>{Lang.inactive}</span>
  }
}


export function humanifyDate (time, dateFormat = undefined) {
  return moment(time).format(dateFormat !== undefined ? dateFormat : Auth.dateFormat)
}

export function getFieldValue(fields: Array<any>, field: string) {
  const ind = _.findIndex(fields, function(o) { return o.name == field; });
  if(ind > -1) {
    return _.get(fields[ind], 'value', '');
  }
  return false;
}