import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ConceptInfoModel } from "../../../model";
import { CONST } from "../../../common";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts";
import { SummerNote } from "../../../common/form/Summernotes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const ConceptInfoForm = ({ form, onChange, remove, editorChange, updateConceptInfo }) => {

  function ActionButton({ index }) {
    return index === 0 ? null :
      <button type={"button"} onClick={() => remove(index)} style={{ marginTop: 25 }} className={"btn btn-danger btn-icon"}><i className={"fa fa-minus"} /></button>
  }

  const [concept_info, set_concept_info] = useState([]);
  useEffect(() => {
    set_concept_info(_.get(form, "concept_info", []))
  }, [form])
 
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) { return; }
    const items = reorder( concept_info,result.source.index,result.destination.index);
    updateConceptInfo(items);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {
              concept_info.map((info: ConceptInfoModel, index: number) => {
                return (
                  <Draggable key={info.id} draggableId={String(info.id)} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={"col-md-12 col-lg-12"} key={index} style={{ borderBottom: "1px solid #d2d3d4", marginTop: 5 }}>
                          <div className="form-group" style={{ marginBottom: 5 }}>
                            <label><strong>Info {index + 1}</strong></label>
                          </div>
                          <div className={"row"}>
                            <div className={"col-md-12 col-lg-12"}>
                              <div className="form-group">
                                <label>Question</label>
                                <input type={"text"} required={true} onChange={onChange(index)} className={'form-control'} name={"title"} value={info.title || ""} placeholder={`Enter Title`} />
                              </div>
                            </div>
                            <div className={"col-md-12 col-lg-12"}>
                              <div className={"row"}>
                                <div className={index === 0 ? "col-md-12 col-lg-12" : "col-md-11 col-lg-11"}>
                                  <div className="form-group">
                                    <label>Enter Content</label>
                                    <SummerNote id={`message_${index}`} {...{ value: info.description || "", onEditorChange: editorChange(index), className: "form-control", name: "description", placeholder: "Enter content" }} />
                                  </div>
                                </div>
                                <div className={"col-md-1 col-lg-1"}>
                                  <ActionButton index={index} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
};
