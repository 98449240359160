import React from "react";
import _ from "lodash";
import {CommonSubmitButton, LoaderType, RenderForm, showNotification, SwitchLoader} from "../../../common";
import { useHistory } from "react-router-dom";
import {AnsFeedbackModel, AgeGroupModel, SportModel, ConceptModel, IFeedbackInput} from "../../../model";
import {IState} from "../Data";
import { AnsFeedbackService, QuestionnaireService, SportService} from "../../../services";
import { Feedbacks } from "./Feedbacks";

export const FeedbackForm = (props: IState) => {
  let history = useHistory();
  const [form, setForm] = React.useState({} as AnsFeedbackModel);
  const [ageGroup, setAgeGroup] = React.useState([] as Array<AgeGroupModel>);
  const [sports, setSports] = React.useState([] as Array<SportModel>);
  const [concept, setConcept] = React.useState([] as Array<ConceptModel>)

  const handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const {name, value} = target;
    setForm({...form, [name]: value})
  }

  React.useEffect(() => {
    setForm(props.feedback)
  },[props.feedback])

  React.useEffect(() => {
    async function init() {
      const ageGroupList: Array<AgeGroupModel> = await QuestionnaireService.getAgeGroups();
      const sportsList: Array<SportModel> = await SportService.getList();
      setAgeGroup(ageGroupList);
      setSports(sportsList);
    }
    init()
  },[])

  React.useEffect(() => {
    async function init() {
      const concepts = await QuestionnaireService.getConceptByAgeGroup(form.age_group_id, form.sport_id);
      setConcept(concepts);
    }
    if(form.age_group_id > 0 &&  form.sport_id > 0) {
      init();
    }
  }, [form.age_group_id, form.sport_id])


    const FormInputs = [
      {
        "name" : "age_group_id",
        "value" : _.get(form, "age_group_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose age group",
        "label": "Age group",
        "options": ageGroup,
        "readonly": props.id !== undefined
      },
      {
        "name" : "sport_id",
        "value" : _.get(form, "sport_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose sport",
        "label": "Sport",
        "options": sports,
        "readonly": props.id !== undefined
      },
      {
        "name" : "concept_id",
        "value" : _.get(form, "concept_id", ""),
        "onChange": handleChange,
        "required": true,
        "type": "select",
        "placeholder": "Choose Concept",
        "label": "Concept",
        "options": concept,
        "readonly": props.id !== undefined
      },
    ];

    function isDisable() {
      const fields = FormInputs.reduce((acc, val) => acc ? acc : val.value === "" && val.required, false);
      if(!fields){
        return form.feedbacks.reduce((acc, item) => acc ? acc : (
          ( _.isUndefined(item.min_marks) || item.min_marks === "") ||
          ( _.isUndefined(item.max_marks) || item.max_marks === "") ||
          ( _.isUndefined(item.feedback) || item.feedback === "")
        ), false );
      }
      return fields;
    }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);

    if(props.id ){
      AnsFeedbackService.update(form, props.id).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/ans-feedback`)
      }).catch(() => {});
    } else {
      AnsFeedbackService.store(form).then((message) => {
        showNotification("success", message);
        history.push(`/quiz/ans-feedback`)
      }).catch(() => {});
    }
  }
  function remove(num: number) {
    const {feedbacks} = form;
    const updatedStatements: Array<IFeedbackInput> = feedbacks.filter((item, index) => index !== num)
    updateFeedback(updatedStatements);
  }
  function addMore() {
    const feedbacks = _.get(form, "feedbacks", []);
    feedbacks.push({} as IFeedbackInput)  ;
    updateFeedback(feedbacks);
  }

  const handleStatements = (index: number) => (event: React.ChangeEvent<HTMLElement>) => {
    const item = form.feedbacks;
    const target: any = event.target;
    const {name, value} = target;
    item[index][name] = value;
    updateFeedback(item)
  }

  function updateFeedback(data:Array<IFeedbackInput>) {
    setForm({
      ...form,
      feedbacks: data
    })
  }

  if(_.isUndefined(form.feedbacks)){
    addMore();
  }

  return(
      <form className="kt-form" id={'customer_form'} onSubmit={handleSubmit}>
        <div className="kt-portlet__body">
          <div className={'row'}>
            <RenderForm items={FormInputs}/>
            <Feedbacks form={form}  remove={remove} onChange={handleStatements} />
            <div className={"col-md-12 col-lg-12"}>
              <div style={{textAlign: "end"}}>
                <button type={"button"} onClick={addMore} style={{marginTop:25}} className={"btn btn-primary btn-icon"}><i className={"fa fa-plus"}/></button>
              </div>
            </div>
          </div>
        </div>
        <CommonSubmitButton disabled={isDisable()}/>
      </form>
    )
}
