import * as React from 'react';
import API from "../../common/AxoisClient";
import {showNotification} from "../../common/Common";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {EditProfileBreadcrumb} from "./Data";
import {ChangePassword} from "../../common/page/ChangePassword";
import {ProfileValidations} from "./Validations";
import {LoaderType, SwitchLoader,SubmitButton} from "../../common";
import {Auth} from "../../Auth/Auth";
import {UserProfileService} from "../../services";

interface IState {
    form: {
        first_name : string;
        last_name : string;
    email : string;
    mobile_no? : string;
    country_code? : string;
    
    };
    id: number;
    countrycodes? : Array<any>;
   
  }

export class EditProfile extends React.Component<RouteComponentProps,IState> {
    state: IState;
    constructor(props: any) {
        super(props);
      
        this.state ={
            form: Auth.userInfo,
            id: Auth.userInfo.id,
           countrycodes : [], 
       }
        
        ProfileValidations();
    }

 
    handleChange = (event: React.ChangeEvent<any>) => {
        const target : any = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ form: { ...this.state.form, [name]: value} });
      }

    handleSelectChange = (event : React.ChangeEvent<HTMLElement>) => {
        const targetSelect : any = event.target;
        const value = targetSelect.value;
        
        this.setState({ form: { ...this.state.form, country_code: value} });

       
    }

    handleSubmit = (event : any) => {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if($("#updateProfile").valid()) {
            API.post("users/edit-profile", this.state.form).then(response => {
                Auth.authenticate(response.data);
                UserProfileService.userprofiledata();
                showNotification("success", Lang.profile_success);
               // localStorage.setItem('userInfo', JSON.stringify(response.data));

            })
        }
    }

    

    render() {
        
        return (
            <div>
                <Breadcrumb data={EditProfileBreadcrumb}/>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                {Lang.my_profile}
                                            </h3>
                                        </div>
                                    </div>
                                    <br/>
                                    <form className="kt-form" id={"updateProfile"} onSubmit={this.handleSubmit}>
                                        <div className="kt-portlet__body">
                                            <div className={'row'}>
                                                <div className={"col-md-6 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.name} <span className={'required'}>*</span></label>
                                                        <input type={'text'} onChange={this.handleChange} value={this.state.form.first_name}  name={'first_name'} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.name} <span className={'required'}>*</span></label>
                                                        <input type={'text'} onChange={this.handleChange} value={this.state.form.last_name}  name={'last_name'} className="form-control" />
                                                    </div>
                                                </div>

                                                <div className={"col-md-6 col-lg-12"}>
                                                    <div className="form-group">
                                                        <label>{Lang.email} <span className={'required'}>*</span></label>
                                                        <input type={'email'} disabled value={this.state.form.email}  name={'email'} className="form-control" placeholder={Lang.getString(`email_placeholder`)} />
                                                    </div>
                                                </div>

                                                <div className={"col-md-6 col-lg-12"}>
                                                    <div className="form-group ">
                                                        <label>{Lang.telephone_number} <span className={'required'}>*</span></label>

                                                            <input type={'text'} onChange={this.handleChange} value={this.state.form.mobile_no } name={'mobile_no'} className="form-control" placeholder={Lang.getString(`mobile_placeholder`)} />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                    </div>
                                                    <div className="col-lg-6 kt-align-right">
                                                    <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">
                                                {Lang.change_password}
                                            </h3>
                                        </div>
                                    </div><br/>
                                    { <ChangePassword id={this.state.id}/> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}